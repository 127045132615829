custom-button {
    padding: 18px 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    outline: 0;
    border: 0;
    margin: 10px 0;
    box-shadow: none;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
}

.theme-button {
    @extend custom-button;
    background-color: var(--blue-dark);
    color: var(--white);
    border: 1px solid transparent;
    white-space: nowrap;

    &:hover {
        color: var(--white);
        // border: 1px solid var(--hover-color);
        background-color: var(--yellow-color) !important;
    }

    &.clear-button {
        padding: 10px 28px;
        background-color: red !important;
        color: var(--white) !important;

        @media screen and (max-width : 568px) {
            width: 100%;
            padding: 13px 28px;
        }
    }

    &.email-button {
        padding: 10px 28px;

        @media screen and (max-width : 568px) {
            width: 100%;
            padding: 13px 28px;
        }
    }

    @media screen and (max-width : 768px) {
        padding: 14px 40px;
    }



    &.disable {
        opacity: 0.6;
        pointer-events: none;
        // cursor: not-allowed;
    }




}

.button-golden {
    @extend custom-button;
    background-color: var(--blue-dark);
    padding: 15px 30px;
    color: var(--white);
    border: 1px solid transparent;

    &:hover {
        background-color: var(--yellow-color);
        // border: 1px solid var(--hover-color);
        color: var(--white);
    }


    &.disable {
        opacity: 0.6;
        pointer-events: none;
    }


    &.clear-button {
        background-color: red !important;
        color: var(--white) !important;

        @media screen and (max-width : 568px) {
            width: 100%;
            padding: 13px 28px;
        }
    }


}



.button-black {
    @extend custom-button;
    background-color: var(--blue-dark);
    border-radius: 0;
    border: 2px solid transparent;
    color: var(--white);

    &:hover {
        background-color: var(--blue-dark);
        color: var(--white);
        border: 2px solid var(--blue-dark);
        outline: 2px solid var(--blue-dark);
    }

    &.size-button {
        padding: 12px 20px;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 14px;

        &:hover {
            outline: 1px solid var(--blue-dark);
        }
    }

    &.size-border {
        background-color: transparent;
        border: 1px solid var(--blue-dark);
        color: var(--blue-dark);
        padding: 12px 35px;
    }
}

.disabled-btn {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.select-plan-btn {
    width: 100%;
}

.previous {
    background-color: var(--blue-dark);
    padding: 10px;
    color: var(--white);
    border-radius: 5px;
    text-align: center;

    @media screen and (max-width : 400px) {
        width: 100%;
    }

    &:hover {
        background-color: var(--yellow-color);
        color: var(--white);
    }
}

.next-2 {
    background-color: var(--blue-dark);
    padding: 10px;
    color: var(--white);
    border-radius: 5px;
    text-align: center;

    @media screen and (max-width : 400px) {
        width: 100%;
    }

    &:hover {
        background-color: var(--yellow-color);
        color: var(--white);
    }
}

.exp {
    color: var(--hover-color);
    text-decoration: underline;
    text-align: center;


    &:hover {
        color: var(--yellow-color);
    }

    @media screen and (max-width : 400px) {
        width: 100%;
    }
}

.p-n {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: 10px 0;

    @media screen and (max-width : 400px) {
        flex-wrap: wrap;
    }
}