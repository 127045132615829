// footer css start 
.footer-menu {
    background-color: var(--blue-dark);
    padding: 40px 0px;
    border-top: 5px solid var(--light-blue-100);

    @media screen and (max-width : 992px) {
        padding: 70px 10px;
    }

    .logo-footer {
        padding-left: 20px;

        @media screen and (max-width:992px) {
            padding-left: 0;
            text-align: center;
        }

        img {
            max-width: 330px;
            width: 100%;
            height: auto;
        }

        .email {
            padding-left: 5px;

            span {
                font-family: 'Corbert';
                font-weight: 900;
                text-transform: uppercase;
                font-size: 16px;
                color: #7b858c;

                a {
                    color: var(--blue);
                    font-weight: 700;
                    text-transform: lowercase;
                }
            }
        }
    }

    .footer-linking {

        display: flex;
        align-items: flex-start;
        justify-content: center;
        column-gap: 70px;
        height: 100%;
        padding-top: 60px;

        @media screen and (max-width:992px) {
            display: none;
        }

        .icon-link {
            display: flex;
            column-gap: 20px;

            li {

                a {
                    width: 37px;
                    aspect-ratio: 1/1;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--yellow-color);

                    &:hover .fa {
                        transform: scale(1.2);
                    }
                }

                .fa {
                    color: var(--blue-dark);
                    font-size: 18px;
                }

            }
        }


        ul {
            padding: 0;
            list-style: none;
            margin-top: 10px;
            margin-bottom: 0;


            li {
                display: block;
                padding: 5px 0;

                a {
                    color: var(--link-color);
                    font-size: 17px;
                    font-weight: 300;
                    letter-spacing: 1px;

                }
            }
        }


    }
}

.footer__content-bottom {
    background-color: var(--yellow-color);
    padding: 20px 20px;

    .container {
        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    .right-to-bear-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width : 992px) {
            flex-direction: column;
            justify-content: center;
            gap: 20px;
        }

        &>div {
            flex: 1;
        }

        .copy-right {
            padding-left: 20px;

            @media screen and (max-width:768px) {
                padding-left: 0px;
            }

            span {
                color: var(--white);
                font-size: 14px;
                letter-spacing: .92px;
                font-weight: 300;

                a {
                    color: var(--white);
                    font-weight: 300;

                    @media screen and (max-width : 992px) {
                        display: block;
                        text-align: center;
                    }

                    &:hover {
                        border-bottom: 1px solid #fff;
                    }
                }
            }
        }

        .bottom-footer-term-condition {
            text-align: center;

            a {
                color: #ffffff;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: .92px;
                font-weight: 300
            }
        }

        .footer__list-social {
            justify-content: flex-end;

            ul {
                list-style: none;
                display: flex;
                margin-bottom: 0;
                gap: 10px;
                padding: 0;

                li {

                    transition: transform 700ms ease 0ms;

                    a {
                        color: var(--white);
                        font-size: 20px;
                        width: 37px;
                        aspect-ratio: 1 / 1;
                        background-color: var(--blue-dark);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50px;

                        &:hover .fa {
                            transform: scale(1.2);
                        }
                    }

                    .fa {
                        font-size: 20px;
                    }

                    &:hover {
                        transform: scale(1);
                    }
                }
            }
        }
    }

}

// footer css end 