@media (max-width: 992px) {
    .mobile-responsive thead {
        display: none;
    }

    .mobile-responsive,
    .mobile-responsive tbody,
    .mobile-responsive tr,
    .mobile-responsive td {
        display: block;
        width: 100%;

    }

    .mobile-responsive td {
        padding-left: 50% !important;
        text-align: right;
        position: relative;
        color: var(--secondary);

        &.td-left {
            padding-left: 13px;
        }
    }

    .mobile-responsive td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
        color: var(--background) !important;
        font-weight: 500;

        @media screen and (max-width : 400px) {
            font-size: 13px;
        }
    }

    .mobile-responsive .top-list::before {
        content: attr(data-label);
        position: absolute;
        top: 13px !important;
    }



    .tb td::before {
        content: attr(data-label);
        position: absolute;
        top: 37% !important;
        color: var(--blue-grey);

    }
}

@media screen and (max-width : 460px) {
    .mobile-responsive .top-list-2::before {
        content: attr(data-label);
        position: absolute;
        top: 13px !important;
    }
}