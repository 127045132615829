custom-input {
    width: 100%;
    padding: 13px 16px;
    border: 1px solid transparent;
    box-shadow: none;
    outline: 0;
    margin-bottom: 15px;
    border-radius: 3px;

    &:focus {
        border: 1px solid var(--black-color);
        box-shadow: none;
    }
}

.input-outline {
    @extend custom-input;
    border: 1px solid var(--input-color);
    color: var(--light-blue);
    padding: 12px;

    &:focus {
        border: 1px solid var(--blue-dark);
    }

    &.padding-add {
        padding: 16px;

        &:focus {
            border: 1px solid var(--blue-dark);
        }
    }
}

.label-text {
    font-size: 17px;
    color: var(--blue);
    text-align: left;
    margin-bottom: 13px;
    font-weight: 600;
    // font-family: 'Avenir-Medium';
}

.input-outline-light {
    @extend .input-outline;

    &:focus {
        box-shadow: none;
        border: 1px solid var(--blue);
    }
}

.form-input {
    @extend custom-input;
    background-color: var(--white);
    color: var(--light-blue);

    &.showed {
        margin-bottom: 25px;

        &:focus {
            outline: 2px solid var(--blue);
            border-radius: 0;

        }
    }

}

// floating input
.form-group-2 {
    width: 100%;

}

.st {
    border: 1px solid rgba(0, 0, 0, .37);
    border-radius: 4px;
    background-color: transparent;
    margin: 0px auto;
    padding: 6px 4px 4px 14px;
    height: 50px;
    outline: none !important;
    font-size: 16px;
    color: var(--blue-dark);
    transition: all .2s ease-in-out;

    &:hover {

        border: 1px solid var(--blue-dark);
    }

    &:focus {
        box-shadow: none;
        border: 1px solid var(--blue-dark);
    }
}

.floating-group {
    margin-bottom: 16px;
    position: relative;
    margin-top: 20px;

    &.floating-b {
        margin-bottom: 0;
    }

    input {
        position: relative;
        display: block;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, .37);
        border-radius: 4px;
        background-color: transparent;
        margin: 0px auto;
        padding: 6px 4px 4px 14px;
        height: 50px;
        outline: none !important;
        font-size: 16px;
        color: var(--blue-dark);
        transition: all .2s ease-in-out;

    }

    label {
        position: absolute;
        top: 20px;
        left: 12px;
        text-align: left;
        display: inline-block;
        padding: 0 4px;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-weight: 400;
        background-color: var(--white);
        color: var(--blue-new-color);
        margin: 0px auto;
        cursor: text;
        transition: all .15s ease-in-out;
        // z-index: 1;
        pointer-events: none;

        @media screen and (max-width : 1199px) {
            font-size: 13px;
        }

    }

    &.select-floating {
        label {
            top: -6px !important;
            color: var(--blue) !important;
            font-weight: 600;
        }
    }

    &.mb-f {
        margin-bottom: 20px;
        margin-top: 10px;

        .form-control {
            &:focus {
                border: 1px solid var(--blue-dark);
                box-shadow: none;
            }
        }
    }

    input:hover,
    input:focus {
        border: 1px solid var(--blue-dark);
        box-shadow: none;
    }

    input:focus+label,
    input:valid+label,
    textarea:focus+label,
    textarea:valid+label {
        top: -6px !important;
        color: var(--blue) !important;
        font-weight: 600;
    }


}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    transform: scale(.85) translateY(-16px) translateX(16px);
    background-color: white;
    color: var(--blue-dark);
    font-weight: 600;
    opacity: 1;
    font-size: 15px !important;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding: 10px;
}

.floating-b {
    margin-top: 20px;
}

.form-floating label {
    padding: 15px 5px !important;
    height: 14px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transform: translate(8px, 10px);
    font-size: 14px !important;
    font-weight: 400;
    color: var(--blue-new-color);
}

.form-floating .form-control {
    height: 50px !important;

    &:focus {
        box-shadow: none;
        border-color: var(--blue-dark);
    }
}

.error-input-border-red {
    border: 2px solid red !important;
}

.react-tooltip {
    background-color: red !important;
    max-width: 300px !important;
    width: 100%;
    z-index: 1;
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
}

.float-g {
    .react-tooltip {
        background-color: var(--blue-dark) !important;
    }
}