.radioBtn {
    padding: 3px;
    width: 100%;

    .radio-input {
        display: none;
    }

    .radio__label {
        font-size: 16px;
        font-weight: 600;
        padding: 14px;
        text-align: left;
        width: 100%;
        border: 1px solid var(--light-blue-400);
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        border-radius: 3px;

        p {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;


            &.text-left {
                justify-content: flex-start;
            }
        }

        &::before {
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            top: 35%;
            right: 20px;
            border-radius: 50px;
        }


    }



    .radio-input:checked+.radio__label {
        background-color: hsl(226.59deg 54.92% 95.2% / 87.8%);
        border: 1px solid var(--blue-dark);
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .radio-input:checked+.radio__label::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        top: 35%;
        right: 20px;
        width: 18px;
        height: 18px;

    }

}

.form-group {
    display: block;
    margin-bottom: 10px;

    .check-box-list {

        display: none;

    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }

    .later-book {
        position: relative;
        cursor: pointer;
        font-size: 14px;
        border: 1px solid var(--light-blue-400);
        width: 100%;
        padding: 13px 50px 13px 13px;
        font-weight: 600;
        font-family: 'Corbert';
        border-radius: 4px;

        &.book-name {
            z-index: 999;
        }

        &::before {
            content: '';
            position: absolute;
            top: 37%;
            right: 20px;
            width: 16px;
            height: 16px;
            border: 1px solid var(--light-blue-400);
            border-radius: 50px;
        }

    }

    .check-box-list:checked+.later-book::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        background-color: var(--blue-dark);
        margin-top: 1px;
        border-radius: 50px;
        border: 1px solid var(--blue-dark);
    }

    .check-box-list:checked+.later-book::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #f0e8e3e0;
        left: 0;
        top: 0;
        z-index: -1;
        border: 1px solid var(--blue-dark);
        border-radius: 4px;
    }
}

.radio-button {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    border: 1px solid var(--light-blue-400);
    margin-left: 20px;

    .radio-input {
        display: none;
    }

    .radio__label {
        font-size: 17px;
        font-weight: 500;
        padding: 12px 30px;
        color: var(--black);

        &:hover {
            background-color: var(--yellow-color);
            color: var(--white);
        }

        p {
            margin-bottom: 0;
        }
    }

    .radio__label-1 {
        @media screen and (max-width : 568px) {
            padding: 5px 10px;
        }
    }

    .radio-input:checked+.radio__label {
        background-color: var(--blue-dark);
        color: var(--white);

        &:hover {
            background-color: var(--yellow-color);
        }

    }
}

.plan-set-card {
    display: flex;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
    margin: 40px 0px 50px;
    gap: 5px;
    row-gap: 10px;

    @media screen and (max-width : 1199px) {
        gap: 15px;
        padding: 0;
    }

    .radioBtn {
        flex: 1 0 270px;
        max-width: calc(33.3% - 10px);
        width: 100%;

        @media screen and (max-width : 568px) {
            max-width: 100%;

        }
    }
}

.billing-list-check {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    @media screen and (max-width : 412px) {
        flex-wrap: wrap;
    }

    .form-check {
        display: flex;
        align-items: flex-end;

        .form-check-label {
            transform: translateY(3px);
        }
    }

    .form-check-input {
        border-radius: 50px !important;
    }

}

.df {
    transform: translateY(3px);
}