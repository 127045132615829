.responsibly {
    padding-left: 40px;
    margin: 160px 0;

    @media screen and (max-width : 992px) {
        padding-left: 0px;
        margin: 60px 0;

    }

    .responsibly-user-text {
        position: relative;
        z-index: 0;
        padding-left: 20px;

        @media screen and (max-width : 992px) {
            padding-left: 0px;
            padding: 0 45px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 70px;
            background-color: var(--white);
            z-index: -1;

            @media screen and (max-width : 992px) {
                left: 0;

            }
        }

        .sub-text-user {
            font-size: 21px;
            font-weight: 500;
            word-spacing: 1px;
            color: var(--grey);
        }

        .list-pointer {
            font-family: 'dashiellfine-medium';


        }


    }

    .gun-images {
        border-top: 1px solid var(--light-blue-400);
        border-bottom: 1px solid var(--light-blue-400);
    }

    .font-change {
        padding-top: 15px;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 20px;
        font-family: 'dashiellfine-medium';


    }

    .blog-list-heading {
        font-weight: 600;
        font-size: 32px;
        font-family: 'dashiellfine-medium';
        line-height: 42px;
        margin-bottom: 20px;

        @media screen and (max-width:992px) {
            font-size: 22px;
            line-height: 32px;

        }
    }
}

.video-frame {
    margin: 20px 0px 40px;
    padding: 0 40px;
    margin: 0 auto;

    @media screen and (max-width : 992px) {
        padding: 0;
    }

    .container {
        @media screen and (min-width: 1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }
}

.bear-facts {
    text-align: center;
    margin: 70px 0 20px;
    padding: 0 50px;


    @media screen and (max-width :992px) {
        padding: 0;
    }

    @media screen and (min-width:1200px) {
        .container {
            max-width: 1340px;
            width: 100%;
        }
    }

    .facts-bear-heading {
        padding: 0 20px;
        margin-bottom: 50px;

        @media screen and (max-width :992px) {
            padding: 0;
        }

        img {
            max-width: 220px;
            width: 100%;

            @media screen and (max-width :768px) {
                margin: 25px 0 20px;
                max-width: 140px;
            }
        }

        h4 {
            font-size: 15px;
            margin-bottom: 0;
            padding: 20px 0 10px;
            font-weight: 600;
            word-spacing: 2px;
            letter-spacing: 1px;
            color: var(--black);
            font-family: 'Condensed';

            @media screen and (max-width :768px) {
                padding: 0 0 10px;
            }
        }

        .sub-hea-edc {
            font-size: 18px;
        }

        p {
            font-size: 16px;
            margin-bottom: 0;
            line-height: 30px;
            color: var(--grey);
            font-family: 'Corbert';
        }
    }
}

.post-blog {
    padding: 0 30px;

    .container {
        @media screen and (min-width :1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    @media screen and (max-width :992px) {
        padding: 0;
    }

    &.card-disable {
        @media screen and (max-width:992px) {
            display: none;
        }
    }

    &.theme-carousel {
        display: none;

        @media screen and (max-width:992px) {
            display: block;
        }
    }

    .blog-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        a {
            font-size: 15px;
            text-decoration: underline;
            color: #000;
            display: none;

            @media screen and (max-width:768px) {
                display: block;
            }
        }
    }

    .blog-heading {
        font-weight: 800;
    }


}

.join-right {
    margin: 0 0 30px;

    .join-file {
        padding: 30px 90px 30px 65px;

        @media screen and (max-width : 992px) {
            padding: 40px;
        }

        .list-pointer {


            .text-liability {
                color: var(--grey);
                font-weight: 600;
                font-size: 21px;
                line-height: 40px;
                font-family: 'Corbert';
            }

        }
    }

    .istock-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 55% center;
    }
}

.shopify-section-template {
    background-image: url('../../../public/images/cta-footerjpg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    padding: 30px 0;
    width: 100%;
    height: 100%;

    .template {
        max-width: 720px;
        width: 100%;
        margin: auto;
    }

    .shopify {
        text-align: center;

        .section-heading {
            color: var(--white);
            margin-bottom: 30px;
            font-size: 46px;
        }

        .email-text {
            font-size: 19px;
            font-weight: 500;
            color: var(--white);
            letter-spacing: 1px;
            font-family: 'Corbert';

            a {
                color: var(--white);
            }
        }

        .check-img-and-text {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            img {
                margin-top: 8px;
            }

            p {
                margin-bottom: 0;
                font-size: 18px;
                color: var(--black);
            }
        }
    }

    .form-floating {
        .form-control {
            height: 46px;
            border-radius: 5px;
        }

        label {
            position: absolute;
            padding: 0.7rem 0.75rem;
            font-size: 16px;
            color: #adb0b3;
        }
    }

    .from-button {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .form-text-last {
        font-size: 13px;
        text-align: center;
        color: var(--white);
        font-weight: 500;
    }

}

.responsible-user-gun {
    margin: 50px 0 30px 0;

    .user-gun-text {
        padding: 38px 80px;

        @media screen and (max-width : 992px) {
            padding: 20px 30px;
        }

        p {
            font-size: 20px;
            line-height: 35px;
            color: var(--grey);

        }
    }

    .button-white {
        border: 1px solid var(--blue);

        &:hover {
            border: 1px solid var(--black);
        }
    }

    .gun-man {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .rifle-percentage {
            padding-left: 10px;

            @media screen and (max-width : 992px) {
                padding: 0;
            }
        }
    }
}

.talk-about {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;

    .title-text {
        font-size: 19px;
        color: var(--blue);
        font-family: "Assistant";
        font-weight: 800;
        letter-spacing: 1.2px
    }

    h3 {
        font-size: 30px;
        font-family: 'dashiellfine-medium';
        font-weight: 600;
        color: var(--black);
    }

    p {
        font-size: 17px;
        color: var(--black);
    }
}


.event-card-list {
    margin: 70px auto;
    width: 100%;
    // padding: 0 80px;

    @media screen and (max-width : 768px) {
        padding: 0;
    }


    // .event-user-man {
    //     max-width: 1080px;
    //     width: 100%;
    //     margin: 0 auto;
    // }

    // @media screen and (max-width :992px) {
    //     max-width: 1140px;
    // }

    // .container {
    //     max-width: 1340px;
    //     width: 100%;
    // }


    .event-heading {
        text-align: center;
        margin: 40px 0px 80px;

        @media screen and (max-width:992px) {
            margin: 20px 0;
        }

        h4 {
            font-size: 20px;
            color: var(--blue-dark);
            font-weight: 600;
            padding: 0px 0 30px;
            text-transform: uppercase;
        }

        p {
            font-size: 17px;
            color: rgb(61, 58, 58);
            line-height: 27px;
        }
    }

    .event-mane-heading {
        font-weight: 600;
        margin-bottom: 10px;
        padding-left: 10px;
    }

    .experiences-card {
        height: 100%;
        width: 100%;
        margin: 10px auto 0;
        padding: 0 10px 8px;
    }

    .card {
        padding: 20px;
        background-color: #f3f3f3;
        border: transparent;
        border-radius: 0;
        width: 100%;
        height: 100%;

        .img-card-shot {
            height: 220px;
            width: 100%;
            object-fit: cover;
        }

        .card-body {
            padding: 0;
            display: flex;
            flex-direction: column;

            .card-heading {
                font-size: 17px;
                color: var(--black);
                padding-top: 20px;
                font-weight: 600;
                letter-spacing: 1px;
            }

            .card-titles {
                font-size: 18px;
                color: var(--grey);
                margin-bottom: 0;
            }

            .card-text {
                font-size: 16px;
                line-height: 30px;
                color: var(--grey);
            }

            .read-more-card {
                font-size: 18px;
                color: var(--hover-color);
                cursor: pointer;
                margin-top: auto;
                font-weight: 300;
                display: flex;
                align-items: center;
                gap: 6px;
                max-width: 120px;
                width: 100%;

                &:hover {
                    color: var(--yellow-color);
                }

                .fa {
                    margin-top: 5px;
                }

                &:hover .fa {
                    padding-left: 2px;
                    transition: all 0.5s ease-in-out;
                    color: var(--yellow-color);

                }
            }
        }
    }

}