@font-face {
    font-family: 'Avenir';
    src: url('../../../public/fonts/Avenir-Light.ttf');
    /* avenir light */
    font-weight: 300;

}

@font-face {
    font-family: 'Avenir';
    src: url('../../../public/fonts/Avenir-Medium.ttf');
    /* avenir medium */
    font-weight: 500;


}

@font-face {
    font-family: 'Avenir';
    src: url('../../../public/fonts/Avenir-Black.ttf');
    /* avenir black */
    font-weight: 600;

}

@font-face {
    font-family: 'Avenir-Heavy';
    src: url('../../../public/fonts/Avenir-Heavy.ttf');
    /* avenir aeavy */
    font-weight: 600;

}

@font-face {
    font-family: 'Helvetica-Neue';
    src: url('../../../public/fonts/Helvetica-Neue-Regular.otf');

}


@font-face {
    font-family: 'Gotham Condensed';
    src: url('../../../public/fonts/Gotham-Condensed-Bold.otf');
    font-weight: 800;
}