.join-set-2 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .dropdown-img {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 10px 20px !important;

        img {
            display: block;
            width: 15px;
        }
    }

    ul {
        padding-left: 0;
        background-color: var(--light-blue-100);

        li {
            padding: 0 !important;
            border-bottom: 1px solid var(--white);

            &:last-child {
                border-bottom: 0px;
            }

            a {
                padding: 10px 0px 10px 40px !important;
                color: #585757 !important;

                &:hover {
                    background-color: var(--blue);
                    color: var(--white) !important;
                }
            }

        }
    }
}

.new-dd {
    background-image: url('../../../public/images/banner-first.png');
    width: 100%;
    min-height: 590px;
    padding: 120px 130px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width : 768px) {
        padding: 50px 20px;
        min-height: 360px;
    }

    .banner-outre {

        width: 100%;
        text-align: center;

    }
}

.participating {
    margin: 50px 0;
    text-align: center;
    font-family: 'dashiellfine-medium';

    .free {
        text-align: left;
        padding-left: 12px;
        font-size: 25px;
        color: var(--blue-gray);
    }

    .range-must {
        text-align: left;
        padding-left: 12px;
        font-style: italic;
        color: var(--blue-gray);
    }

    .includes {
        text-align: left;
        color: var(--blue-gray);
        padding-left: 12px;
    }

    ul {
        padding-left: 30px;
        text-align: left;
        list-style: none;

        li {
            line-height: 35px;
            font-size: 20px;
            padding-left: 10px;

            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: -20px;
                top: 10px;
                width: 15px;
                height: 15px;
                background-color: var(--blue);
                border-radius: 50px;
            }
        }
    }
}

.plan-select-mon-yer {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;

    .form-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 300px;
        text-align: center;
        margin: 0 auto;
        border: 2px solid var(--blue-dark);
        padding: 10px;
        border-radius: 10px;

        &:first-child {
            padding-left: 0;
            padding-left: 10px;
        }

        &:last-child {
            text-align: right;
        }

        input {
            width: 50px;
            padding: 10px;
            border-radius: 50px !important;
            margin-left: 7px;
            transform: translateY(-3px);
        }

        .month {
            background-color: var(--blue-dark);
            border: 1px solid var(--blue-dark) !important;
        }

        .yearly {
            background-color: var(--yellow-color) !important;
            border: 1px solid var(--yellow-color) !important;
        }

        .form-check-input:checked[type=checkbox] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
        }

        .form-check-input {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
        }

        .m {
            color: var(--blue-dark);

        }

        .a {
            color: var(--yellow-color);
        }

        label {
            flex: 1;
            font-size: 18px;
            font-weight: 800;
        }
    }


}

.ps {
    .form-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 300px;
        text-align: center;
        margin: 0 auto;
        border: 2px solid var(--blue-dark);
        padding: 10px;
        border-radius: 10px;

        &:first-child {
            padding-left: 0;
            padding-left: 10px;
        }

        &:last-child {
            text-align: right;
        }

        input {
            width: 50px;
            padding: 10px;
            border-radius: 50px !important;
            margin-left: 7px;
            transform: translateY(-3px);
        }

        .month {
            background-color: var(--blue-dark);
            border: 1px solid var(--blue-dark) !important;
        }

        .yearly {
            background-color: var(--yellow-color) !important;
            border: 1px solid var(--yellow-color) !important;
        }

        .form-check-input:checked[type=checkbox] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
        }

        .form-check-input {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
        }

        .mo {
            color: var(--blue-dark);
            padding-right: 30px;
        }

        .y {

            padding-left: 30px;
        }

        .a {
            color: var(--yellow-color);
        }

        label {
            flex: 1;
            font-size: 18px;
            font-weight: 800;
        }
    }
}

.card-price {
    max-width: 1150px;
    margin: 50px auto 50px;
    display: grid;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 0 15px;

    &.pd-outer {
        padding: 0 140px;

        @media screen and (max-width : 992px) {
            padding: 0 15px;

        }

    }

    .card {
        border-radius: 5px;
        border: 0px;
        text-align: center;
        max-width: 355px;
        width: 100%;
        margin: 0 auto;
        box-shadow: none;

        @media screen and (max-width : 768px) {
            max-width: 100%;
        }

        .visibility-img {
            visibility: hidden;
        }

        img {
            width: 100%;

            @media screen and (min-width : 668px) {
                max-height: 250px;
                min-height: 225px;
            }
        }

        .price-dcpt {
            display: flex;
            flex-direction: column;
            min-height: 80px;
            margin-bottom: 10px;
        }

        .description-price {
            text-align: left;
            font-family: 'Gotham Condensed';
            color: var(--yellow-color);
            font-size: 50px;
            font-weight: 800;
            line-height: 10px;
            margin-bottom: 0;
            margin-top: auto;
            margin-bottom: auto;

            .font-c {
                font-weight: 600;
                font-family: 'Avenir-Heavy';
            }

            span {
                font-size: 15px;
                color: var(--blue-dark);
                font-family: 'Avenir';
                font-weight: 500 !important;
            }
        }

        .card-img-price {
            width: 100%;
            height: 230px;
            background-color: var(--white);
            border-radius: 17px;

            @media screen and (max-width : 568px) {
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .card-body {
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width : 412px) {
                padding: 30px 10px;
            }

            h5 {
                font-size: 15px;
                color: var(--blue-dark);
                align-self: flex-start;
                text-transform: uppercase;
                font-weight: 600;
            }

            ul {
                padding: 0;
                list-style: none;
                padding-left: 15px;
                text-align: left;
                font-weight: 500;

                li {
                    font-size: 15px;
                    position: relative;
                    color: var(--black);
                    padding-bottom: 10px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: -20px;
                        top: 2px;
                        width: 11px;
                        height: 11px;
                        background-image: url('../../../public/images/bullet_star.svg');
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;

                    }
                }
            }

            button {
                margin-top: auto;
                padding: 12px 35px;
                background-color: var(--blue-dark);
                font-size: 15px;
                text-transform: capitalize;

                @media screen and (max-width : 768px) {
                    width: 100%;
                }
            }
        }
    }
}

.member-list {
    padding: 50px 0;

    ul {
        padding-left: 35px;

        li {
            text-decoration: none;
            padding-bottom: 10px;
            font-size: 19px;
            color: var(--grey);
            line-height: 30px;
        }
    }
}

.map-location {
    margin: 0px 0 50px;
    padding: 10px 60px;

    @media screen and (max-width : 768px) {
        padding: 10px 5px 50px;
    }

    h3 {
        font-size: 20px;
        color: #002060;
    }

    p {
        font-size: 16px;
        font-weight: 300;
        color: #4D4D4D;
        line-height: 24px;
        margin-bottom: 20px;

    }

    .map-select {
        width: 100%;
        height: 500px;
        z-index: 0;

        @media screen and (max-width : 767px) {
            height: 300px;
        }
    }
}

.map-select-area {

    position: relative;

    .clear-button {
        background-color: transparent !important;
        color: red !important;
        text-decoration: underline;
        text-align: center;
    }

    .form-input-location {
        display: flex;
        gap: 10px;

        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        padding: 15px;

        @media screen and (max-width : 992px) {
            width: 100%;
            padding: 15px 0;
            flex-wrap: wrap;
        }

        &>div {
            @media screen and (max-width : 992px) {
                display: block;
                width: 100%;

            }

            .pac-target-input {
                @media screen and (max-width : 992px) {
                    width: 100%;
                    margin-bottom: 0;
                }
            }
        }

        button {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 15px;
            padding: 12px 30px;
            border-radius: 9px;
            margin-top: 0;
            margin-bottom: 0;
        }

        .list-select-map {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: var(--white);
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
            width: 100%;
            padding: 20px;
            z-index: 1;
        }
    }

    .pac-target-input {
        width: 460px;
        padding: 11px 16px;

        @media screen and (max-width :1199px) {
            width: 200px;
        }

        &:focus {
            box-shadow: 0 0 0 0.1rem var(--blue);
            border-color: transparent;
            background-color: #fff;
        }

        @media screen and (max-width : 992px) {
            width: 100%;
        }

        @media screen and (max-width : 769px) {
            height: 100%;
            padding: 11px 16px;
            color: var(--input-color);
            width: 100%;
        }
    }

    .input-outline {
        margin-bottom: 0;
        border: 1px solid var(--blue-dark);
        border-radius: 8px;
        font-weight: 500;
        color: #9C9E9D;



        @media screen and (max-width : 992px) {
            width: 100%;
            margin-bottom: 10px;
        }

        &.second-input {
            @media screen and (max-width : 992px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        &.select-miles {
            width: 150px;
            background-position: right 5px center;
            cursor: pointer;

            @media screen and (max-width : 992px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    .filter-name {
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;

        @media screen and (max-width : 992px) {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
        }

        @media screen and (max-width : 400px) {
            gap: 10px;
        }
    }

    button {
        padding: 9px 10px;

        @media screen and (max-width : 992px) {
            width: 100%;
            margin-bottom: 0px;
            padding: 9px 14px;
        }
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        li {
            font-size: 14px;
            white-space: nowrap;
            padding-left: 20px;
            cursor: pointer;
            color: #9C9E9D;
            font-weight: 500;

            &.first,
            .second {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 1px;
                    width: 17px;
                    height: 17px;
                    background-color: #534582;
                    border-radius: 50px;
                    border: 1px solid #707070;
                }
            }

            &.second {
                &::before {
                    background-color: #A40000;
                }
            }

            &.third {
                &::before {
                    background-color: #337AB7;
                }
            }

            &.four {
                &::before {
                    background-color: var(--white);
                }
            }
        }
    }

    .hover-point {
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        width: 100%;

        .fa {
            font-size: 12px;
            padding-left: 5px;
        }
    }

    .list-select-map {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: var(--white);
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        padding: 20px;
        z-index: 1;

        .filter-select {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            @media screen and (max-width : 768px) {
                flex-direction: column;
                justify-content: center;

            }

            p {
                margin-bottom: 0;
            }
        }

        .check-list-filter {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            @media screen and (max-width : 768px) {
                grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
            }
        }

        .form-check {
            margin: 5px 0;

            .form-check-input {
                padding: 0;
            }

            label {
                font-size: 14px;
                padding-top: 2px;
            }
        }
    }
}

.form-select {
    appearance: none !important;
}

.filter-list {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    flex: 1;

    @media screen and (max-width : 992px) {
        justify-content: center !important;
    }

    ul {
        @media screen and (max-width : 992px) {
            margin-bottom: 20px;
        }
    }

    .img-filter {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 11px;
        border-radius: 8px;
        background-color: var(--blue-dark);
        cursor: pointer;

        &:hover {
            background-color: var(--yellow-color);
        }

        @media screen and (max-width : 768px) {
            margin-top: 10px;
        }

        img {
            width: 15px;
            text-align: center;
        }

        p {
            font-family: 'Avenir-Medium';
            color: var(--white);
            font-size: 12px;
            margin-bottom: 0;
        }
    }
}

.heading-listing {
    position: relative;
    margin: 30px 0px 12px;
    display: flex;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--blue-dark);
    }

    h5 {
        color: var(--blue-dark);
        font-size: 16px;
        text-align: center;
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0px 20px;
        white-space: nowrap;
        font-weight: 600;

    }
}

.k-listing {
    column-gap: 25px;
    row-gap: 10px;

    li {
        font-size: 16px !important;
    }
}

.book-now-m {
    background-color: var(--blue-dark);
    border: 0;
    padding: 12px 10px;
    color: var(--white);
    font-size: 14px;
    border-radius: 6px;
}

.h-m {
    font-size: 18px;
    color: var(--blue-dark);
    font-family: "Avenir-Heavy";

}

.m-b {
    @media screen and (max-width : 992px) {
        flex-wrap: wrap;
    }

    label {
        font-size: 15px;
        color: var(--blue-dark);
    }
}

.list-map-user {
    display: flex;
    gap: 20px;

    @media screen and (max-width : 1199px) {
        padding: 0;
    }

    @media screen and (max-width : 992px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding: 0;
    }

    @media screen and (max-width : 412px) {

        padding: 0;
    }

    .listing-user-set {
        max-width: 370px;
        width: 100%;

        @media screen and (max-width : 992px) {
            max-width: 100%;
            width: 100%;
        }
    }

    .user-mpa-locating-check {
        width: 100%;
    }
}

.find-local {
    font-size: 50px;
    text-align: start;
    padding-top: 10px;
    padding-left: 90px;
    font-family: 'Gotham Condensed';
    color: #E7E5D8;
    margin-bottom: 0;
    padding-bottom: 0;

    @media screen and (max-width : 768px) {
        font-size: 45px;
        text-align: center;
    }

    @media screen and (max-width:768px) {
        padding-left: 0;
    }
}

.book-now {
    background-color: var(--yellow-color) !important;
}

.button-link {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    transform: translateX(10px);

    a {
        padding: 8px 5px;
        background-color: var(--blue-dark);
        text-align: center;
        border-radius: 5px;
        font-size: 14px;
        color: var(--white);
        display: flex;
        // gap: 5px;
        align-items: center;
        justify-content: center;
        flex: 1;

        &:hover {
            background-color: var(--yellow-color);
        }

        svg {
            width: 15px;
            height: 15px;
            margin-right: 5px;

            @media screen and (max-width : 412px) {
                width: 12px;
                height: 12px;
            }
        }

        img {
            width: 15px;
            height: 15px;

            @media screen and (max-width : 412px) {
                width: 12px;
                height: 12px;
            }
        }

        @media screen and (max-width : 420px) {
            font-size: 10px;
        }
    }

    @media screen and (max-width : 420px) {
        flex-wrap: nowrap;
    }
}

.cc {
    stroke-width: 1.92px;
    stroke: #fff;
    stroke-miterlimit: 10;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.location-list {
    height: 500px;
    overflow: auto;


    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: #E7E5D8;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--blue-dark);
        border-radius: 10px;
    }

    @media screen and (max-width : 767px) {
        margin-top: 20px;
        overflow: auto;

    }

    .league-city {
        cursor: pointer;
        padding: 20px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--yellow-color);

        @media screen and (max-width : 1199px) {
            padding-left: 0;
        }

        @media screen and (max-width : 768px) {
            border-bottom: 1px solid var(--yellow-color);
        }

        &.bor-add-right {
            @media screen and (max-width : 768px) {
                border-bottom: 1px solid var(--yellow-color);
            }
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom: 0px;
        }

        @media screen and (max-width : 767px) {
            flex: 1 0 370px;
            margin-bottom: 0;
            margin-right: 10px;

            &:last-child {
                margin-right: 0px;
                border-right: 0px;
            }
        }

        @media screen and (max-width : 399px) {
            flex: 1 0 280px;
            margin-bottom: 0;
            margin-right: 10px;

            &:last-child {
                margin-right: 0px;
            }
        }

        .city-type {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px;

            .listing-data {
                flex: 1 0 70%;
            }

            .location-image {
                flex: 1 0 30%;
                text-align: center;

                img {
                    width: 80px;
                    height: 100%;

                }
            }

            h4 {
                font-size: 16px;
                color: var(--blue-dark);
                font-family: 'Avenir-Heavy';
            }

            ul {
                list-style: none;
                padding: 0;

                margin-bottom: 0;

                li {
                    font-size: 16px;
                    color: var(--blue-gray);
                    font-weight: 300;
                }
            }


        }

        .error-css {
            font-size: 18px;
            color: red;
            text-align: center;
        }

        .error-text-css {
            font-size: 15px;
            text-align: center;
        }
    }

    .list-area-map {

        .time-list-style {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;

            .hours-list {
                font-weight: 600;
            }

            li {
                padding-right: 3px;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            span {
                padding-right: 0px;
                font-weight: 500;
            }
        }

        ul {
            padding: 0;
            margin-bottom: 0;
            padding-left: 17px;

            li {
                color: var(--blue-gray);
                font-size: 14px;
                padding-bottom: 5px;

                &.button-link {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    width: 100%;

                    a {
                        padding: 8px 5px;
                        background-color: var(--blue-dark);
                        text-align: center;
                        border-radius: 5px;
                        font-size: 14px;
                        color: var(--white);
                        display: flex;
                        gap: 6px;
                        align-items: center;
                        justify-content: center;

                        .fa {
                            font-size: 12px;
                        }

                        @media screen and (max-width : 420px) {
                            font-size: 12px;
                        }
                    }

                    @media screen and (max-width : 420px) {
                        flex-wrap: nowrap;
                    }
                }



                a {
                    padding: 8px 20px;
                    flex: 1;
                    margin-bottom: 0;
                    text-transform: capitalize;

                }

                span {
                    font-weight: 600;
                    font-size: 16px;
                    padding-right: 5px;
                }
            }
        }
    }
}

.owl-carousel {
    z-index: 0 !important;
}

.footer__list-social {

    list-style: none;
    display: flex;
    margin-bottom: 0;
    gap: 23px;

    li {

        transition: transform 700ms ease 0ms;

        .fa {

            font-size: 30px;

            &:hover {

                transform: scaleX(1.05) scaleY(1.05);
            }

        }

        &:hover {
            transform: scale(1);
        }
    }
}

// congratulations css start

.congratulations {
    margin: 50px 0;

    .con-membership {
        text-align: center;

        h4 {
            border-bottom: 1px solid var(--blue);
            text-align: left;
            margin-bottom: 0;
        }

        img {
            margin-top: 40px;
            width: 120px;
            margin-bottom: 30px;
        }

        .multiple {
            border-bottom: 1px solid var(--blue);
        }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                @extend .bear-text;
                padding: 10px 0;
            }
        }

    }

    .mem-plan {
        .mm-p {
            max-width: 500px;
            width: 100%;
            margin: 30px auto;

            h5 {
                background-color: var(--light-blue-400);
                color: var(--black);
                padding: 5px;
            }

            .form-check {
                display: flex;
                align-items: center;

                .form-check-input {
                    border-radius: 50px;
                    margin-right: 5px;
                }

                p {
                    flex: 1;
                    width: 100%;
                    text-align: right;
                    margin-bottom: 0;
                }

            }
        }
    }
}

// congratulations css end

// new css

.select-gender {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-check {
        display: flex;
        align-items: center !important;

        .form-check-input {
            padding: 10px;
            border-radius: 50px !important;
            margin-right: 5px;
            border: 1px solid var(--blue);


            &:checked {
                border-radius: 50px;
            }
        }
    }
}

.form-check {
    display: flex;

    .form-check-input {
        padding: 9px;
        margin-right: 5px;
        border: 1px solid var(--blue) !important;

    }
}

.select-mm {
    border: 1px solid var(--light-blue-400);
    padding: 20px;
    height: 160px;
    overflow: auto;
}

.last {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .back {
        color: var(--black);
        font-size: 22px;
    }
}

// new css end

// header css start

.join-button {
    padding-right: 20px;

    .link-join {
        color: var(--input-color);
        font-weight: 600;
        font-size: 16px;
        font-family: "Corbert";
        text-transform: uppercase;
        display: flex;
        align-items: center;

        &:hover {
            color: var(--blue);
        }

        &:focus {
            color: var(--blue);
        }

        .fa {
            padding-left: 5px;
        }

        &.active-drop {
            color: var(--blue);
        }

    }

    .dropdown-menu {
        transform: translate(-27px, -40px);

        li {
            a {
                &:hover {
                    background-color: var(--blue) !important;
                    color: var(--white) !important;
                }
            }
        }
    }
}

.billing-range {

    h4 {
        border-bottom: 1px solid var(--blue);
        margin-bottom: 40px;

        @media screen and (max-width : 768px) {
            margin-bottom: 10px;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            @extend .bear-text;
        }
    }
}

// header css end

.sub-em {
    form {
        display: flex;
        align-items: center;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;

        input {
            margin-right: 10px;

            @media screen and (max-width : 568px) {
                margin-right: 0px;
            }
        }

        @media screen and (max-width : 568px) {
            flex-wrap: wrap;
        }
    }

}

// Terms and conditions css start

.privacy-terms {

    h3 {
        font-size: 30px;
        font-family: 'Gotham Condensed';
        word-spacing: 2px;
        color: var(--blue-dark);
        margin-bottom: 0;
        margin-top: 20px;
    }

    p {
        font-size: 18px;
        color: #848686;
        margin-bottom: 20px !important;
    }

    h6 {
        font-size: 20px;
        color: var(--blue-dark);
        font-weight: 600;
        padding-top: 20px;
    }

    .list-service-our {
        list-style: none;
        padding-left: 60px;

        li {
            font-size: 18px;
            font-weight: 500;
            padding-bottom: 15px;
            color: #848686;
        }
    }

    .browser {
        padding-left: 15px;

        li {
            padding-bottom: 20px;
            color: #848686;
            font-size: 18px;
        }
    }

    .browser-m {
        padding-left: 15px;

        li {
            padding-bottom: 20px;
            margin-left: 25px;
            color: #848686;
            font-size: 18px;
        }
    }

    strong {
        color: var(--blue-dark);
    }

    .comply {
        padding-left: 20px;

        li {
            padding-bottom: 20px;
            font-size: 18px;
            font-weight: 500;
            color: #848686;
        }
    }
}

.terms-and-conditions {
    .conditions {
        h3 {
            font-size: 30px;
            font-family: 'Gotham Condensed';
            word-spacing: 2px;
            color: var(--blue-dark);
            margin-bottom: 0;
            margin-top: 20px;
        }

        .mt-h {
            margin-top: 20px;
        }

        h4 {
            font-size: 20px;
            color: #848686;
            margin-bottom: 0;
            padding: 10px 0;
            font-weight: 500;

            @media screen and (max-width : 568px) {
                font-size: 19px;
            }
        }

        p {
            font-size: 1.1rem;
            color: #7b858c;
            margin-bottom: 0;
        }

        ul {
            margin-bottom: 0;

            @media screen and (max-width : 768px) {
                padding: 0;
                padding-left: 15px;

            }

            li {
                padding-bottom: 5px;
                font-size: 1.1rem;
                color: #7b858c;

            }
        }

        .ps-list {
            padding-left: 70px;

            @media screen and (max-width : 768px) {
                padding-left: 25px;

            }
        }

        .color-h {
            color: var(--blue);
            font-weight: 600;
        }
    }
}

// Terms and conditions css end

.button-listing-sub {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 28px;
    width: 100%;
}

.close-modal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.apply-21 {
    p {
        font-size: 15px;
        color: var(--grey-light);
        font-weight: 500;
        margin-bottom: 0;

        @media screen and (max-width : 992px) {
            margin-bottom: 50px;
        }
    }
}

.caliber-card {
    background-color: var(--yellow-color);
    margin-top: 70px;
    padding: 150px 130px;
    position: relative;

    &.cal-card-2 {
        padding: 150px 130px 40px;
        margin-top: 0;

        @media screen and (max-width:992px) {
            padding: 150px 20px 40px;
        }
    }

    @media screen and (max-width:992px) {
        padding: 150px 20px;
    }

    @media screen and (max-width:412px) {
        padding: 110px 5px 60px;
    }

    .brand-emblem {
        width: 170px;
        aspect-ratio: 1/ 1;
        border-radius: 50%;
        background-color: var(--link-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px solid #C3996B;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    h4 {
        text-align: center;
        font-size: 50px;
        font-family: 'Gotham Condensed';
        color: #E7E5D8;
    }

    p {
        color: var(--white);
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .join-form {
        display: flex;
        gap: 20px;
        max-width: 600px;
        width: 100%;
        margin: 20px auto;
        font-weight: 500;

        @media screen and (max-width : 568px) {
            flex-wrap: wrap;
        }

        button {

            font-weight: 500;
            font-size: 15px;
            padding: 1px 45px;

            @media screen and (max-width : 568px) {
                width: 100%;
                padding: 12px 45px;
            }
        }

        input {
            ::placeholder {
                color: #9C9E9D;
            }

            @media screen and (max-width : 568px) {
                padding: 12px 16px;
            }
        }
    }
}

.find-range-map {

    height: 350px;
    position: relative;
    margin-top: 25px;
    display: flex;
    overflow: hidden;

    @media screen and (max-width : 992px) {
        flex-wrap: wrap;
        overflow: visible;
    }

    .map-select {
        position: absolute !important;
        left: calc(100% - 80%);
        top: 0;
        width: 100%;
        height: 100%;
        margin-right: -90px;

        @media screen and (min-width : 1500px) {
            left: calc(100% - 100%);
        }

        @media screen and (max-width : 992px) {
            left: 0 !important;
        }
    }

    .find-range-list {
        position: relative;
        z-index: 1;
        background-color: var(--blue-dark);
        max-width: 580px;
        width: 100%;
        height: 100%;
        clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
        padding-left: 200px;
        padding-top: 35px;
        padding-right: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 90px;

        @media screen and (max-width : 992px) {
            max-width: 100%;
            top: 100%;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        }

        h4 {
            color: var(--white);
            font-size: 38px;
            font-family: 'Gotham Condensed';
            font-weight: 800;
        }

        .form-select {
            width: 250px;
            padding: 10px 20px;
            font-weight: 500;

            @media screen and (max-width : 992px) {
                width: 100%;
            }

            &:focus {
                box-shadow: none;
                border-color: transparent;
                outline: 0;
            }
        }

        button {
            background-color: var(--blue-dark);
            border: 1px solid var(--white);
            border-radius: 7.6px;
            font-size: 16px;
            font-weight: 300;
            padding: 7px 18px;
            color: var(--link-color);
            margin-top: 20px;

            &:hover {
                background-color: var(--yellow-color);
                border: 1px solid var(--yellow-color);
            }
        }

        img {
            width: 220px;
            height: 140px;
            opacity: 38%;
            margin-top: auto;
            margin-left: auto;

            @media screen and (max-width : 568px) {
                right: 40px;
            }
        }
    }

}

.about-net {
    // margin-top: 70px;

    @media screen and (max-width : 992px) {
        margin-top: 42ch;
    }

    h4 {
        color: var(--blue-dark) !important;
    }
}

.lear-more {
    text-align: center;
    margin-bottom: 30px;

    button {
        display: block;
        margin: 40px auto;
        background-color: var(--blue-dark);
        padding: 12px 30px;
        font-size: 15px;
        color: var(--link-color);
        font-family: 'Avenir-Medium';
        font-weight: 500;
    }
}

.clint {
    background-image: url('../../../public/images/clint.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 530px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;

    p {
        max-width: 568px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        background-color: #FFFFFF91;
        color: var(--blue-dark);
        font-size: 15px;
        font-family: 'Avenir-Heavy';
        padding: 40px;
    }
}

.user-create-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    width: 76%;
    margin: 20px auto;
    grid-column-gap: 40px;

    @media screen and (max-width : 568px) {
        width: 100%;
        margin: 0 auto;
    }

    .card {
        border: 0;
        background-color: transparent;
        text-align: center;
        align-items: center;

        .img-set {
            width: 200px;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .card-body {
            padding-top: 0;
        }

        h5 {
            font-family: 'Gotham Condensed';
            font-size: 50px;
            color: var(--yellow-color);
            margin-bottom: 29px;
        }

        p:nth-child(2) {
            color: var(--yellow-color) !important;
        }

        p {
            font-size: 16px;
            font-weight: 500;
            color: var(--blue-dark);
            margin-bottom: 0;
        }
    }
}

.being-caliber-card {
    padding: 20px 80px;

    @media screen and (max-width : 768px) {
        padding: 20px 0px;
    }

    h3 {
        text-align: center;
        font-size: 36px;
        font-family: 'Gotham Condensed';
        color: var(--blue-dark);
        padding-bottom: 50px;
        margin-bottom: 0;
    }

    .net-work-card {
        background-image: url('../../../public/images/benefit_image_1.png');
        width: 100%;
        height: 410px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        padding-bottom: 50px;
        margin-bottom: 150px;

        @media screen and (max-width : 992px) {
            background-position: top;
            margin-bottom: 40px;
        }

        &.second {
            background-image: url('../../../public/images/benefit_image_2.png');
        }

        &.third {
            background-image: url('../../../public/images/benefit_image_3.png');
        }

        .net-emblem {
            display: flex;
            align-items: center;
            position: relative;
            background-color: #ffffff8e;
            padding: 20px 0px 20px 20px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            width: 100%;

            p {

                font-size: 13px;
                font-weight: 500;
                color: var(--blue-dark);
                max-width: 200px;
                width: 100%;
                margin-bottom: 0;
            }

            .emblem {
                position: absolute;
                top: -23px;
                right: 0;
                flex: 1 0 108px;
                aspect-ratio: 1/ 1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px;
                background-color: #E7E5D8;
                border-radius: 50%;

                @media screen and (max-width : 768px) {
                    top: -34px !important;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

    }

}

.join-card {
    padding: 120px 0;

    .join {
        position: relative;

        h4 {
            color: var(--blue-dark);
            font-size: 50px;
            font-family: 'Gotham Condensed';
            text-align: center;
            background-color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 3px 20px;
            white-space: nowrap;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--blue-dark);
        }
    }
}

.range-network-registration {
    padding: 40px;

    @media screen and (max-width : 768px) {
        padding: 50px 0px;
    }

    h3 {
        font-size: 38px;
        color: var(--blue-dark);
        font-family: 'Gotham Condensed';
        padding-bottom: 30px;
        padding-left: 0px;

        @media screen and (max-width : 568px) {
            text-align: center;
        }
    }

    h4 {

        background-color: var(--yellow-color);
        font-family: 'Avenir-Heavy';
        font-size: 15px;
        padding: 8px;
        margin-bottom: 0;
        margin-bottom: 30px;
    }

    .plan-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
        margin-bottom: 20px;

        @media screen and (max-width :568px) {
            flex-wrap: wrap;
            margin-bottom: 25px;
        }

        .form-check {
            align-items: center;

            @media screen and (max-width : 568px) {
                align-items: flex-start;
                margin-bottom: 7px;

            }

            .form-check-input {
                margin-top: 0;
                transform: translateY(-3px);

                @media screen and (max-width : 567px) {
                    transform: translateY(0px);
                }
            }

            input {
                border-radius: 50px;
            }

            label {
                font-family: 'Avenir-Heavy';
                padding-top: 0px;
                font-size: 20px;

                @media screen and (max-width : 568px) {
                    font-size: 16px;
                }
            }
        }

        p {
            font-size: 18px;
            color: #707070;
            font-family: 'Avenir-Heavy';
            text-align: center;
            line-height: 20px;
            padding-top: 0;
            margin-bottom: 0;

            @media screen and (max-width : 568px) {
                text-align: left;
                font-size: 15px;
                padding-left: 25px;
            }

            span {
                font-size: 16px;
                font-family: 'Helvetica-Neue';
            }
        }
    }

    label {
        font-size: 20px;
        color: var(--blue-dark);
        font-family: 'Avenir-Heavy';
    }

    .form-control {
        width: 300px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    p {
        font-size: 16px;
        font-weight: 500;
    }

    .discount-code {
        display: flex;
        justify-content: space-between;
        margin-top: 70px;

        @media screen and (max-width: 568px) {
            flex-wrap: wrap;
        }


        .proceed {
            text-align: right;
            width: 100%;

            @media screen and (max-width: 568px) {
                text-align: center;

            }
        }
    }
}

.method-found {
    text-align: center;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        margin-top: auto;
        font-size: 18px;
        color: var(--black);
        margin-bottom: 0;
    }

    a {
        font-weight: 500;
        font-size: 15px;

        &:hover {
            color: var(--yellow-color);
        }
    }
}

.text-link {
    font-weight: 500;
    font-size: 15px;

    &:hover {
        color: var(--yellow-color);
    }
}

.t-center {
    @media screen and (max-width : 499px) {
        text-align: center;
    }
}

.dropstart {
    padding-left: 60px;
}

.but-show {
    margin-right: 60px;

    @media screen and (max-width : 1199px) {
        margin-right: 30px;
    }
}

.btn-no-show {
    margin-right: 120px;

    @media screen and (max-width : 1199px) {
        margin-right: 30px;
    }
}

.map-location h3 {
    font-weight: 600;
}

.map-location p {
    font-weight: 500;
}

.gm-style-iw-d {

    h4 {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 500;
    }
}

.payment-new {
    width: 100%;
    max-width: 840px;

    p {
        text-decoration: underline;
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 30px;
        color: var(--hover-color);
        cursor: pointer;
        display: inline-block;

        &:hover {
            color: var(--yellow-color);
        }
    }

    .b-a-c {
        display: flex;
        width: 100%;
        column-gap: 24px;

        a {
            flex: 1;
        }
    }
}

.affiliate-id {
    max-width: 450px;
    width: 100%;
    margin: 10px auto;
    position: relative;

    .info-icon {
        width: 25px;
        aspect-ratio: 1/1;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(38px, 10px);

        @media screen and (max-width : 512px) {

            transform: translate(0px, 10px);
        }
    }

    .change-w {


        @media screen and (max-width : 512px) {
            max-width: 340px;
            margin: inherit;
        }

        @media screen and (max-width : 370px) {
            max-width: 290px;
            margin: inherit;
        }
    }


}

.t-y-u {
    text-align: center;
    padding: 100px 0;

    h3 {
        color: #198754;
    }

}

.make-can {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

@media screen and (max-width : 992px) {
    .make-can {
        justify-content: center;
        gap: 10px;
    }
}

.log-in-from .lost {
    font-size: 42px;
    padding-bottom: 10px;
    font-family: "Gotham Condensed";
}

.plan-summary {
    padding: 30px 20px 0;
    margin-top: 13px;
    // max-width: 990px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width : 512px) {
        padding: 30px 10px 0;
    }

    .s-m {
        display: flex;
        align-items: center;
        justify-content: space-between;


        @media screen and (max-width : 540px) {
            justify-content: center;
            row-gap: 20px;
            flex-direction: column;
        }


        h4 {
            font-size: 12px;
            margin-left: 10px;
            letter-spacing: 3px;
            color: rgb(165, 156, 156);
            padding-bottom: 11px;
            text-transform: uppercase;
        }

        .p-change {
            display: flex;

            .radioBtn {
                padding: 0px !important;


                .radio__label {
                    border-radius: 0 !important;
                    padding: 10px 40px !important;
                    cursor: pointer;
                }

                .rb-1 {
                    border-top-left-radius: 5px !important;
                    border-bottom-left-radius: 5px !important;
                }

                .rb-2 {
                    border-top-right-radius: 5px !important;
                    border-bottom-right-radius: 5px !important;
                }
            }
        }
    }

    .c-p {
        margin: 60px auto 0px;

        @media screen and (max-width : 512px) {
            padding: 0;
        }

        .hooray {
            // text-align: center;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 500;
            color: var(--hover-color);
            text-align: center;
        }

        .fee-t {
            margin-top: 40px;

            tbody {



                tr {
                    td {
                        text-align: left;
                        padding: 15px 10px;
                        font-size: 17px;
                        font-weight: 500;
                        color: var(--blue-grey);
                    }

                    th {
                        text-align: right;
                        font-size: 17px;
                        font-weight: 500;
                        color: var(--black);
                        padding: 15px 10px;

                    }
                }
            }

            tfoot {
                border-color: transparent !important;

                tr {
                    td {
                        text-align: left;
                        font-size: 17px;
                        font-weight: 500;
                        color: var(--blue-gray);
                        padding: 15px 10px;
                    }

                    th {
                        text-align: right;
                        font-size: 20px;
                        font-weight: 600;
                        color: var(--blue-dark);
                        padding: 15px 10px;
                    }
                }
            }
        }
    }
}

.h5class {

    font-size: 12px;
    margin-left: 10px;
    letter-spacing: 3px;
    color: rgb(112, 105, 105);
    text-transform: capitalize;

}