@import './components/';
@import './outercss/';

.overflow__hidden {
    overflow: hidden;
}

a {
    text-decoration: none;
}

body {
    font-family: 'Avenir';
    font-weight: 500;
}

.banner {
    background-image: url('../../public/images/home-hero.webp');
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 102px 70px;

    @media screen and (max-width:768px) {
        padding: 42px 10px;
    }

    .banner-outre {
        max-width: 650px;
        width: 100%;
        margin-bottom: 30px;

        @media screen and (max-width:768px) {
            margin-bottom: 0px;
        }

        @media screen and (max-width: 992px) {
            padding-left: 0px;
            text-align: center;
        }

        .outer-title {
            font-size: 25px;
            color: var(--white);
            font-family: 'Assistant';
            margin-bottom: 32px;
            font-weight: 500;
        }
    }
}

.list-pointer {
    ul {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            align-items: flex-start;
            padding: 10px 0;

            img {
                width: 12px;
                padding-top: 5px;
            }

            p {
                font-weight: 600;
                color: var(--blue-dark);
                font-size: 22px;
                letter-spacing: 0.38px;
                margin-bottom: 0;
                padding-left: 10px;
            }
        }
    }

    .list-available {
        color: var(--grey);

        @media screen and (max-width:768px) {
            text-align: center;
        }
    }
}

.form-floating {
    .form-control {
        height: 46px;
        border-radius: 5px;
    }

    label {
        position: absolute;
        padding: 0.7rem .75rem;
        font-size: 15px;
        color: grey;
    }
}

// about css start

.about-banner {
    background-image: url('../../public/images/about-banner.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 125px 130px;
    width: 100%;
    height: 590px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width : 768px) {
        padding: 80px 10px;
        height: 100%;
    }

    .about-outer {
        .banner-heading {
            text-align: center;
        }
    }
}

.liberty-outer {
    padding: 45px 40px;
    text-align: center;

    @media screen and (max-width : 992px) {
        padding: 30px 0px 50px;
    }

    .container {
        @media screen and (min-width : 1200px) {
            max-width: 1340px;
        }
    }

    .liberty-text {
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 140px;

        @media screen and (max-width : 1199px) {
            padding: 0;
        }

        @media screen and (max-width : 668px) {
            padding: 0;
            text-align: left;
        }

        h4 {
            color: var(--yellow-color);
            text-transform: uppercase;
            font-size: 36px;
        }

        p {
            font-weight: 500;
            color: var(--blue-dark);
            line-height: 26px;
            font-size: 16px;
        }
    }

    .title-text {
        font-size: 19px;
        color: var(--blue-dark);
        font-family: 'Assistant';
        font-weight: 800;
        letter-spacing: 1.2px;
    }

    .sub-title-2 {
        font-size: 30px;
        font-weight: 600;
        line-height: 35px;
        font-family: 'Gotham Condensed';
        word-spacing: 2px;
    }

    .bear-text {
        font-size: 19px;
        color: var(--grey);
        line-height: 30px;
    }

    .sub-title-list {
        font-size: 19px;
        color: var(--black);
        font-family: 'dashiellfine-medium';
        font-weight: 600;
        margin-top: 20px;
        padding: 0 70px;
        word-spacing: 2px;

        @media screen and (max-width :992px) {
            padding: 0 14px;
        }
    }

    .right-to-bear-text {
        font-size: 17px;
        font-family: 'Corbert';
        font-weight: 600;
        color: var(--grey);
    }

    .percentage {
        margin: 70px 0 20px;
    }

    .right-status {
        padding: 50px 0px;
        text-align: left;

        @media screen and (max-width:768px) {
            padding: 50px 0 0;
        }

        .status-right-bear {
            h4 {
                font-size: 17px;
                font-weight: 700;
            }

            p {
                font-size: 17px;
                color: var(--grey);
                line-height: 25px;
            }
        }

        .join-btn {
            text-align: center;

            .theme-button {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
            }
        }

    }

}

// about css end

// education css start
.banner-self {
    background-image: url('../../public/images/Screenshot.png');
}

.post-log {
    padding: 0;
    background-image: url('../../public/images/banner_calibar.jpg');
    width: 100%;
    min-height: 540px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    display: flex;
    align-items: center;
    justify-content: left;

    @media screen and (max-width : 412px) {
        min-height: 100%;
        padding: 60px 0;
    }

    &.shoot-img {
        background-image: url('../../public/images/banner-first.png');
    }

    @media screen and (max-width : 768px) {
        background-position: center center;
    }

    .hero-section {
        background-color: #1a2a3f8f;
        max-width: 800px;
        width: 100%;
        padding: 20px 0px 20px 200px;
        position: relative;

        @media screen and (max-width : 768px) {
            padding: 20px 0px 20px 50px;
        }

        p {
            font-size: 20px;
            color: var(--white);
            font-family: 'Avenir-Medium';
        }

        h3 {
            color: var(--yellow-color);
            font-family: 'Gotham Condensed';
            font-weight: 800;
            font-size: 50px;
            margin-bottom: 0;
            line-height: 60px;

            @media screen and (max-width : 412px) {
                font-size: 40px;
                line-height: 40px;
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 100%;
            background-color: var(--yellow-color);
        }
    }
}

.post-log-video {
    position: relative;
    width: 100%;

    .video-add {
        width: 100%;
        height: 540px;
        object-fit: cover;
        position: relative;
    }

    .hero-section {
        background-color: #1a2a3f8f;
        max-width: 800px;
        width: 100%;
        padding: 20px 0px 20px 200px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-0%, -50%);

        @media screen and (max-width : 768px) {
            padding: 20px 0px 20px 50px;
        }

        p {
            font-size: 20px;
            color: var(--white);
            font-family: 'Avenir-Medium';
        }

        h3 {
            color: var(--yellow-color);
            font-family: 'Gotham Condensed';
            font-weight: 800;
            font-size: 50px;
            margin-bottom: 0;
            line-height: 60px;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 100%;
            background-color: var(--yellow-color);
        }
    }

}

.outer-card {
    color: var(--black);

    &:hover {
        color: var(--black);
    }

    .card {
        border-radius: 0;
        border: transparent;
        transform: scale(1);
        overflow: hidden;
        transition: all 0.5s ease-in-out;

        .blog-image {
            overflow: hidden;

            img {
                width: 100%;
                height: 250px;
                object-fit: cover;

            }
        }

        .card-body {
            .card-title {
                font-size: 20px;
                font-weight: 600;
            }

            .card-text {
                font-size: 13px;
                color: var(--grey);
            }

            .card-sub-title {
                font-size: 18px;
                line-height: 27px;
                color: var(--grey);

                @media screen and (max-width : 992px) {
                    margin-bottom: 40px;
                }
            }
        }

        &:hover img {
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
            overflow: hidden;
        }

        &:hover .card-title {
            text-decoration: underline;
        }
    }

    .card-body {
        @media screen and (max-width : 992px) {
            text-align: left;
            padding: 15px 5px;
        }
    }
}

.line {
    p {
        line-height: 20px !important;
    }
}

.list-policy {
    display: flex;

    .list-image {
        transform: translateY(-15px);
    }

    img {
        width: 12px;
        margin-right: 10px;
    }

    p {
        font-weight: 600;
        color: var(--blue-dark);
        font-size: 19px;
        letter-spacing: 0.38px;
        margin-bottom: 0;
        padding: 10px 0;
    }
}

// education css end


// coverage css start

.select-user-plan {
    text-align: center;
    padding: 30px 0 30px;

    @media screen and (max-width : 768px) {
        padding: 30px 0 0;
    }

    .select-heading {
        font-size: 18px;
        font-weight: 600;
        color: var(--blue-dark);
        letter-spacing: 1px;
        padding-bottom: 25px;
    }

    .sub-title {
        text-transform: capitalize;
        font-size: 30px;

        @media screen and (max-width : 768px) {
            font-size: 25px;
        }
    }
}

.coverage {
    margin-bottom: 10px;
    padding-top: 20px;

    .coverage-police {
        padding-left: 5px;
        margin-bottom: 100px;

        @media screen and (max-width :992px) {
            padding-left: 0;
        }

        .sub-title {
            font-size: 28px;
            padding-bottom: 15px;
        }

        .cover-ages-text {
            border-left: 7px solid var(--blue-dark);


            p {
                font-size: 18px;
                color: var(--blue-dark);
                font-weight: 600;
                padding: 5px 10px;
            }
        }
    }

}

// coverage css end


// events css start

.event {
    background-image: url('../../public/images/events.png');
    text-align: center;
    position: relative;
    z-index: -1;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(14, 1, 1, 0.233);
    }

    .banner-heading {
        position: relative;
        z-index: 1;
    }

    p {
        font-size: 20px;
        font-weight: 500;
        color: var(--white);
        position: relative;
        z-index: 1;
        padding-top: 37px;
    }
}

// events css end

// faq page css start

.faq-banner {
    background-image: url('../../public/images/faq_hero.webp');
}

.faq-question {
    margin: 100px auto 40px;
    padding: 0 40px;



    @media screen and (max-width:992px) {
        margin: 50px auto 40px;
        padding: 0 0px;
    }

    .container {
        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;
        }

        @media screen and (min-width:1399px) {

            padding: 0 55px;
        }
    }

    .accordion-item {
        border: 0;

        .accordion-button {
            font-size: 18px;
            color: var(--blue-dark);
            font-weight: 600;
            padding: 14px 10px;
            background-color: transparent;
            border-radius: 0;
            position: relative;
            box-shadow: none;

            &:hover {
                color: var(--yellow-color);
            }

            &:focus {
                // box-shadow: 0 0 0 0.10rem #00206093;
                box-shadow: 0 0 0 0.10rem var(--yellow-color);
                z-index: 0;
                position: relative;
                border-radius: 0 !important;
            }

            &:first-child {
                border-top: 1px solid var(--light-blue-400);
            }

            &:last-child {
                border-bottom: 0px !important;
            }
        }

        .accordion-body {
            padding: 0px 10px 0px 50px;

            @media screen and (max-width : 992px) {
                padding: 0 10px;
            }

            .body-title {
                font-size: 17px;
                color: var(--blue-grey);
                font-weight: 500;
                margin-bottom: 0;
                padding: 14px 0;
            }

            .font-list-civil {
                padding: 14px 0px;

                @media screen and (max-width : 768px) {
                    padding: 14px 40px;

                }

                ul {
                    padding: 0;
                    // list-style: none;
                    margin-bottom: 0px;

                    li {
                        font-size: 16px;
                        color: var(--blue-grey);
                        font-weight: 500;
                        padding-bottom: 5px;
                    }
                }

                ol {
                    padding: 0;
                    margin-bottom: 0;

                    li {
                        font-size: 16px;
                        color: var(--blue-grey);
                        font-weight: 500;
                        padding-bottom: 5px;
                    }
                }
            }
        }

        .accordion-button:not(.collapsed)::after {
            background-image: url('../../public/images/minimize.png');
        }

        .accordion-button::after {
            background-image: url('../../public/images/plus.svg');

        }

        .accordion-button:not(.collapsed) {
            color: var(--yellow-color);
            box-shadow: 0 0 0 0.10rem var(--yellow-color);
        }
    }
}

// faq page css end

// join form css start

.submit {
    max-width: 705px;
    width: 100%;
    padding: 100px 0;
    margin: 0 auto;

    @media screen and (max-width : 992px) {
        padding: 100px 20px;
    }

    h3 {
        text-align: center;
        font-size: 40px;
        word-spacing: 4px;
        color: var(--blue-dark);
        font-family: 'Gotham Condensed';

        @media screen and (max-width : 392px) {
            font-size: 30px;
        }
    }

    .account {
        text-align: center;
        font-size: 17px;
        color: var(--blue-grey);
        padding-top: 20px;

        a {
            &:hover {
                color: var(--yellow-color);
            }
        }
    }
}

// join form css end

.table {
    margin-bottom: 0;
}

.tab-list-user {
    padding: 30px 20px 0;
    margin-top: 13px;

    @media screen and (max-width : 768px) {
        padding: 30px 0 0;
    }

    .nav-tabs {
        border: 1px solid transparent;
        align-items: center;
        padding-left: 9px;

        a {
            color: var(--blue-dark);

            .fa {
                transform: translateX(9px);
                color: var(--blue-new-color);
            }
        }

        .nav-item {

            .nav-link {
                background-color: transparent;
                border: 1px solid transparent;
                color: var(--text-color);
                font-size: 14px;
                padding: 8px 0;

                &.color-gray {
                    color: var(--blue-new-color);
                }

                .fa {
                    transform: translateX(9px);
                    color: var(--blue-new-color);
                }

                &:hover {
                    border: 1px solid transparent;
                }

                &.active {
                    color: var(--blue-dark);
                    font-weight: 600;
                }
            }
        }
    }
}

.join-form-user {
    padding: 0 15px;
    margin: 10px auto 30px;

    .outer-event-policy {
        border: 1px solid #cacdcf;
        padding: 32px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        @media screen and (max-width :992px) {
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 32px 10px;
        }

        h4 {
            font-size: 18px;
            color: var(--blue-grey);
            font-weight: 500;
            margin-bottom: 0;
            line-height: 30px;

            @media screen and (max-width:992px) {
                padding-bottom: 15px;
            }
        }
    }

    .billing-plan {
        font-size: 12px;
        margin-left: 10px;
        letter-spacing: 3px;
        color: rgb(165, 156, 156);
        padding-bottom: 11px;

        @media screen and (max-width : 992px) {
            margin: 0;
        }
    }

    .bill-address {
        font-size: 14px;
        letter-spacing: 2px;
        color: rgb(165, 156, 156);
        font-weight: 500;
    }

    .page-link-all {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;

        a {
            font-size: 15px;
            color: var(--grey);

            .fa {
                margin-left: 10px;
                color: var(--grey);
            }

            &.active {
                color: var(--blue-dark);
            }
        }
    }

    .put-filed-same {
        display: flex;
        align-items: center;

        label {
            font-size: 17px;
            font-weight: 400;
            margin-right: 10px;
            color: rgb(172, 162, 162);
        }

        input {
            width: 23px;
            height: 23px;
            border-radius: 3px !important;

        }
    }

    .order-heading {
        text-align: left;
        font-size: 12px;
        color: var(--yellow-color);
        font-weight: 500;
        letter-spacing: 2px;
        padding-top: 7px;
        padding-bottom: 14px;
        padding-left: 9px;

        @media screen and (max-width : 1192px) {
            padding-bottom: 8px;
        }
    }

    .order-summary {
        border: 1px solid var(--light-blue-400);

        .cover-yearly {
            padding: 18px 10px;
            text-align: center;
            border-bottom: 1px solid var(--light-blue-400);
            display: flex;
            justify-content: space-between;
            background-color: var(--yellow-color);

            h4 {
                font-size: 20px;
                font-weight: 600;
                color: var(--white);
                margin-bottom: 0;
            }


            .plan-select {
                text-align: right;

                .form-switch {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 220px;
                    text-align: center;
                    margin: 0 auto;

                    &:first-child {
                        padding-left: 0;
                        padding-left: 10px;
                    }

                    &:last-child {
                        text-align: right;
                    }

                    input {
                        width: 50px;
                        padding: 10px;
                        border-radius: 50px !important;
                        margin-left: 7px;
                        transform: translateY(-3px);
                    }

                    .month {
                        background-color: var(--blue-dark);
                        border: 1px solid var(--blue-dark) !important;
                    }

                    .yearly {
                        background-color: var(--yellow-color) !important;
                        border: 1px solid var(--blue-dark) !important;
                    }

                    .form-check-input:checked[type=checkbox] {
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
                    }

                    .form-check-input {
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
                    }

                    .m {
                        color: var(--blue-dark);
                    }

                    .a {
                        color: var(--yellow-color);
                    }

                    label {
                        flex: 1;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }

            p {
                font-size: 14px;
                color: var(--white);
                font-weight: 600;
                margin-bottom: 6px;
            }
        }
    }

    .items-plan {
        margin-bottom: 0;
        padding: 0;


        tbody {
            background-color: var(--white);

            tr {
                border: 1px solid transparent;
                border-bottom-width: 0 !important;
                padding: 0;
                border-style: none !important;

                td {
                    padding: 5px;
                }
            }
        }

        .plan {
            font-size: 15px;
            margin-bottom: 0;
            padding-left: 4px;
            font-weight: 500;
            color: var(--grey);
        }

        .price {
            font-size: 18px;
            font-weight: 600;
            text-align: right;
            padding-right: 4px;
            margin-bottom: 0;
        }

        .price1 {
            @extend .plan;
            text-align: right;
            padding-right: 4px;
        }

        .total {
            padding: 10px;
            font-size: 15px;
            color: var(--blue-grey);
            font-weight: 600;
            letter-spacing: 1px;
            margin-bottom: 0;
        }

        .all-total {
            font-size: 20px;
            text-align: right;
            padding-right: 4px;
            font-weight: 600;
            padding: 10px 4px;
            margin-bottom: 0;
        }

        .last-total {
            border-top: 1px solid var(--light-blue-400) !important;
            padding: 10px;
            background-color: var(--white);
        }
    }
}

.form-check {
    .form-check-input {
        border-radius: 3px;

        &:focus {
            box-shadow: none;
            border: 1px solid var(--light-blue-400);
            border-radius: 3px;
        }
    }

    .form-check-input:checked {
        background-color: var(--blue-dark);
        border-radius: 3px;
        border: 0px;
    }
}

.user-all-id {
    padding-bottom: 40px;
    overflow: auto;
    position: relative;
    margin-top: 55px;

    @media screen and (max-width : 768px) {
        margin-top: 10px;
    }

    @media screen and (max-width : 412px) {
        padding: 0;
    }

    .table {
        margin-bottom: 0;
        width: 100%;
        transform: translateY(-20px);

        @media screen and (max-width : 992px) {
            transform: translateY(0px);
        }

        .table-user-line {
            border-top: 1px solid transparent;
        }

        tr {
            @media screen and (max-width : 992px) {

                flex-direction: row !important;
                align-items: center;
                justify-content: space-between;
            }

            th {
                border-color: rgba(197, 197, 197, 0.534) !important;
                padding-left: 50px;
            }

            td {
                border-color: rgba(197, 197, 197, 0.534);
            }
        }

        .user-name {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 600;
            color: var(--blue-grey);

        }

        .full-address-user {
            margin-bottom: 0;
            font-size: 17px;
            font-weight: 500;
            line-height: 25px;
            color: var(--blue-new-color);
            line-height: 30px;
            padding-left: 50px;
            margin-bottom: 30px;

            @media screen and (max-width : 768px) {
                padding-left: 0;
                margin-bottom: 0;
                overflow-wrap: break-word;

            }

            span {
                font-size: 15px;
                font-weight: 600;
                color: var(--blue-dark);

            }

            @media screen and (max-width: 992px) {
                margin-left: 0px;
            }

        }

        .table-date-title {
            font-size: 17px;
            font-weight: 500;
            color: var(--blue-grey);
            margin-bottom: 0;
            padding: 17px 0;
            width: 250px;
            white-space: nowrap;

            @media screen and (max-width:1192px) {
                width: 190px;
            }

            @media screen and (max-width:992px) {
                width: 100px;
            }
        }

        .yearly-list-user {
            font-size: 16px;
            color: var(--blue-grey);
            font-weight: 600;
            padding: 17px 0 17px 45px;
            margin-bottom: 0;

            @media screen and (min-width : 769px) {
                padding: 17px 0 17px 15px;
            }

            @media screen and (max-width : 768px) {
                padding: 17px 0 17px 0px;
                text-align: center;
            }
        }

        .table-date-title-add {
            @extend .table-date-title;
            padding: 30px 0;
        }

        .bond {
            list-style: none;
            padding: 0;
            width: 100%;
            padding-left: 44px;

            .yearly-list-user-add {
                font-size: 16px;
                color: var(--blue-grey);
                font-weight: 500;
                padding: 0px 20px 0 0;
                margin-bottom: 0;
                flex: 1;
                display: flex;
                justify-content: space-between;
            }
        }

        .yearly-list-user-add {
            font-size: 16px;
            color: var(--blue-grey);
            font-weight: 500;
            padding: 30px 0;
            margin-bottom: 0;
        }

        .dollar {
            font-size: 17px;
            color: var(--black);
            padding: 17px 0;
            margin-bottom: 0;
            text-align: right;
            padding-right: 20px;
            font-weight: 500;
        }

        .dollar-add {
            @extend .dollar;
            padding: 30px 15px;
        }

        .dollar-1 {
            @extend .dollar;
            color: var(--blue-dark);
            font-weight: 600;
            font-size: 21px;

            @media screen and (max-width : 992px) {
                padding-right: 0;
                text-align: right;
                display: flex;
            }

            span {
                @media screen and (max-width : 768px) {

                    margin-left: 0px !important;
                }

                @media screen and (max-width : 992px) {
                    flex: 1;
                    text-align: left;
                    margin-left: 14px;
                }


            }
        }
    }
}

.plan-list-price {
    @media screen and (max-width : 992px) {
        display: flex;
        justify-content: flex-end;
    }

    span {
        @media screen and (max-width : 992px) {
            flex: 1;
            text-align: right;

        }

        @media screen and (min-width: 992px) {
            display: none;
        }
    }
}

.screen-first {
    @media screen and (max-width:992px) {
        display: none !important;
    }
}

.button-back {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-top: 0;
    width: 100%;



    @media screen and (max-width:768px) {
        width: 100%;

    }

    &.new-add-button {
        padding: 0 11px;

        @media screen and (max-width:768px) {
            text-align: center;
            padding: 0 0px;
        }
    }

    .back-text {
        font-size: 18px;
        color: var(--grey);
        font-weight: 600;
        background-color: transparent;
        box-shadow: none;
        border: 0;
        cursor: pointer;
    }

}

.input-set {
    position: relative;
}

.details-user-form {

    .details {
        font-size: 12px;
        margin-bottom: 0;
        padding: 10px 22px 0px;
        color: var(--grey);
        letter-spacing: 2px;
    }

    .input-group-text {
        position: absolute;
        top: 0;
        right: 0px;
        height: 50px;
        padding: 10px 7px;
        border: 0;
        background-color: transparent !important;

        img {
            width: 20px;
        }
    }

    .address-details {
        font-size: 12px;
        color: var(--grey);
        letter-spacing: 2px;
        padding: 30px 10px 20px;

        span {
            font-weight: 600;
        }
    }

    .address-details-1 {
        padding: 0;
        font-size: 12px;
        color: var(--grey);
        letter-spacing: 2px;
        padding: 7px 10px 0px;
        margin-bottom: 0;
    }
}

.outer-form {
    margin-top: 0;
}

.list-bil {
    margin: 0;
    padding-top: 10px;
}

.form-membership {
    width: 100%;
    max-width: 350px;
    font-size: 13px;
    color: rgb(19 19 163);
    font-weight: 500;
}

.outer-form {

    .bill-address {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;

    }

    .form-select {
        position: relative;
        background-image: url('../../public/images/arrows.svg');
        background-size: 20px 50px;

    }
}

// Log in from css start
.login-height {
    min-height: calc(100vh - 70px - 464px);
}

.login-height-2 {
    min-height: calc(100vh - 70px - 450px);
}

.log-in-from {
    padding: 85px 0;
}

.log-in-form {
    max-width: 500px;
    width: 100%;
    margin: 0px auto;

    .new-password {
        font-size: 19px;
        margin-bottom: 24px;
        font-weight: 500;

        span {
            color: var(--yellow-color);
        }
    }

    .log-account-text {
        font-size: 42px;
        text-align: center;
        padding-bottom: 10px;
        font-family: 'Gotham Condensed';
    }

    .account-text {
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        color: var(--blue-grey);

        .get-text {
            color: var(--hover-color);

            &:hover {
                color: var(--yellow-color);
            }
        }
    }

    .forgot-text {
        text-align: center;
        display: block;
        font-size: 16px;
        color: var(--hover-color);
        max-width: 150px;
        margin: 20px auto 0;

        &:hover {
            color: var(--yellow-color);
        }
    }

    .reset-password-change {
        font-size: 16px;
        color: var(--grey);
        text-align: center;
        margin-bottom: 0;
        padding: 10px 0 20px;
    }

    .input-group-text {
        position: absolute;
        top: 0;
        right: 0px;
        height: 50px;
        padding: 10px 7px;
        border: 0;
        background-color: transparent !important;
        cursor: pointer;

        img {
            width: 20px;
        }
    }

}

.login-button {
    margin-top: 20px;
}

@media screen and (max-width:768px) {
    .login-button {
        width: 100%;
        padding: 12px !important;
        // margin: 0;
        margin-bottom: 20px;
    }
}

// Log in from css end

// carry responsibly css start

.carry {
    background-image: url('../../public/images/event-2.jpg');
}

.carry-responsibly-user {
    margin: 40px auto;
    font-weight: 500;
    padding: 0 40px;

    @media screen and (max-width:992px) {
        padding: 0 0px;
    }

    .carry-user-gun {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
    }

    .container {
        @media screen and (min-width : 1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    .carry-title {
        .carry-heading {
            text-transform: capitalize;
            font-size: 30px;
            font-weight: 600;
            letter-spacing: 1.4;

            @media screen and (max-width:992px) {
                font-size: 23px;
            }
        }

        p {
            font-size: 18px;
            padding-top: 10px;
            color: var(--grey);
            line-height: 25px;
        }

        ul {
            padding: 0;
            list-style: none;
            border-left: 7px solid var(--blue-dark);
            margin: 20px 0 10px;

            li {
                padding-left: 10px;
                font-size: 21px;
                font-weight: 600;
                color: var(--blue-dark);
                line-height: 31px;
            }
        }

        .line-image {
            margin: 70px 0 90px;
        }
    }

    .card-number {
        margin-top: 20px;
        padding: 0 15px;

        .number {
            font-size: 14px;
            font-weight: 600;
        }

        .card-number-text {
            font-size: 1.1rem;
            color: var(--grey);
            line-height: 30px;
        }
    }
}

// carry responsibly css start

// blog card css start
.blog-card {
    margin: 50px auto;
    padding: 0 40px;

    @media screen and (max-width:992px) {
        padding: 0 0;
        margin: 10px auto;
    }

    .container {
        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    .blog-bear-heading {
        font-size: 32px;
        font-weight: 600;
        padding: 10px 10px 15px;
        word-spacing: 3px;

    }

    .first-blag-outer {
        text-align: center;
        color: var(--black);

        .card {
            border: 0;
            border-radius: 0px;

            .blog-image {
                overflow: hidden;

                .blog-gun-user {
                    width: 100%;
                    height: 450px;
                    object-fit: cover;

                    @media screen and (max-width:992px) {
                        height: 100%;
                    }
                }
            }

            .card-body {
                max-width: 700px;
                width: 100%;
                margin: 0 auto 30px;

                @media screen and (max-width :992px) {
                    text-align: left;
                }


                .card-title {
                    font-size: 20px;
                    font-weight: 600;
                }

                .card-text {
                    font-size: 13px;
                    color: var(--grey);
                }

                .card-sub-title {
                    font-size: 17px;
                    line-height: 27px;
                    color: var(--grey);
                }

            }

            transform: scale(1);
            overflow: hidden;
            transition: all 0.5s ease-in-out;

            &:hover img {
                transform: scale(1.1);
                transition: all 0.5s ease-in-out;
                overflow: hidden;
            }

            &:hover .card-title {
                text-decoration: underline;
            }
        }


    }

    .page-linking {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        list-style: none;

        li {
            padding: 0 10px;

            a {
                color: rgb(18 18 18);
                font-size: 15px;

            }

            &.active {
                border-bottom: 1px solid rgb(18 18 18);
            }
        }


    }
}

// blog card css end

// right-to-bear-personal css start

.personal-gun {
    padding: 0px 10px 30px;

    @media screen and (max-width:992px) {
        padding: 0px 0px 30px;
    }

    .container {
        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;

        }
    }

    .insurance-gun-user {
        max-width: 780px;
        width: 100%;
        margin: 0 auto;
        display: block;
        height: 270px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width : 768px) {
            padding: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .handgun {
        max-width: 755px;
        width: 100%;
        margin: 23px auto 30px;
        padding: 0;

        @media screen and (max-width:992px) {
            margin: 25px auto 30px;
            padding: 10px;
        }

        .handgun-title {
            font-size: 35px;
            font-weight: 600;
            color: var(--black);
            word-spacing: 2px;

            @media screen and (max-width:768px) {
                font-size: 25px;
                padding-top: 14px;
            }
        }

        .handgun-sub-title {
            font-size: 13px;
            word-spacing: 2px;
            color: var(--grey);
        }
    }

    .protection {
        max-width: 780px;
        width: 100%;
        margin: 0 auto;

        .share-link {
            img {
                width: 15px;
                margin-right: 10px;
            }

            color: var(--grey);
            font-size: 15px;
        }

        .owning-text {
            font-size: 16px;
            color: #504e4e;
            font-weight: 600;
            line-height: 30px;
            padding-top: 20px;
        }

        .owning-text-1 {
            font-style: italic;
            @extend .owning-text;
            color: rgb(154, 154, 160);
            font-weight: 500;
        }

        .text-change {
            font-weight: 300 !important;
            font-size: 16px !important;
            color: rgb(98, 98, 104) !important;
        }

        .gun-link-firearm-1 {
            font-size: 17px;
            color: rgb(127 124 124);
            font-weight: 300;
            line-height: 30px;
            padding: 10px 0;

            ul {
                padding: 0;
                padding-left: 18px;
            }
        }

        .gun-link-firearm {
            font-size: 17px;
            color: var(--black);
            font-weight: 300;
            line-height: 30px;
            padding: 10px 0;

            a {
                color: #423333;
                text-decoration: underline;
                font-size: 16px;
                font-weight: 600;

                &:hover {
                    text-decoration-thickness: 0.1rem;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 0;


                &.dote {
                    list-style: disc !important;
                    padding-left: 25px;
                }
            }

            ol {
                li {
                    padding: 5px 0;

                    a {
                        font-weight: 600;
                    }
                }
            }
        }

        .blog-back {
            text-align: center;

            a {
                font-size: 20px;
                color: var(--black);

                .fa {
                    margin-right: 10px;
                    font-size: 20px;
                }
            }
        }
    }

}

// right-to-bear-personal end

// company vision  css start

.speak {
    background-image: url('../../public/images/hero-2.jpg');
}

.american {
    padding: 45px 0;

    .american-title {
        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--blue-dark);
            letter-spacing: 2px;
        }

        h4 {
            font-size: 30px;
            padding-top: 10px;
            font-weight: 600;
            line-height: 30px;
            color: var(--black);
            letter-spacing: 1.2px;

            @media screen and (max-width:992px) {
                font-size: 23px;

            }
        }
    }
}

// company vision css end

// support css start

.box-card-price {
    padding: 60px 200px 20px;

    @media screen and (max-width:992px) {
        padding: 40px 0px 20px;
    }


}

.first-card-price {
    border: 1px solid rgb(219, 219, 219);
    border-radius: 5px;
    height: 100%;

    .call {
        font-size: 15px;
        font-weight: 600;
        border-bottom: 1px solid rgb(219, 219, 219);
        padding: 20px 13px;
        margin-bottom: 0;
    }

    .service {
        padding: 13px 13px 50px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--blue-new-color);
        }

        p {
            font-size: 18px;
            font-weight: 500;
            color: var(--blue-dark);

            a {
                color: var(--blue-dark);
            }
        }
    }
}

// support css end

// affiliate-banner css start

.affiliate-banner {
    background-image: url('../../public/images/affiliate.jpg');
    padding: 185px 0;
}

.details-net {
    padding: 45px 0px 10px;

    .details-net-text {
        h3 {
            font-size: 30px;
            color: var(--black);
            font-weight: 600;
            letter-spacing: 1px;
            padding-bottom: 20px;
        }

        p {
            font-size: 17px;
            line-height: 30px;
            color: var(--blue-new-color);
        }
    }


}

.line-image {
    margin: 100px 0 90px;

    .container {
        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }
}

.qualifying {
    .quint-heading {
        font-size: 19px;
        font-weight: 600;
        letter-spacing: 1px;
        padding-bottom: 20px;
    }

    .quint-2 {
        padding-top: 80px;
        padding-bottom: 0;
        color: rgba(18, 18, 18, 1);
    }

    .sites {

        img {
            width: 13px;
            margin: 20px 0;
        }

        p {
            font-size: 16px;
            color: var(--blue-dark);
            margin: 0;
        }
    }
}

// affiliate-banner css end

.instructor {
    background-image: url('../../public/images/instructor-portal.jpg');
    padding: 190px 0;
}

.coming-soon {
    text-align: center;
    padding: 20px 0;

    h2 {
        font-size: 40px;
        color: #000;
        font-weight: 500;
    }
}

.alert_inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    padding: 8px 0;
    align-items: center;
    justify-content: center;

    @media screen and (max-width : 768px) {
        justify-content: flex-start;
    }

    color: var(--white);
    cursor: pointer;

    &.error-background {
        background-color: rgb(201 57 57);
    }

    &.info-background {
        background-color: var(--yellow-color)
    }

    span {
        font-size: 18px;
        letter-spacing: 1px;
        flex: 1;
        text-align: center;
        padding-left: 10px;
        font-weight: 500;

        @media screen and (max-width:768px) {
            font-size: 16px !important;
            padding-right: 30px;
        }
    }

    .close__alert {
        color: #fff;
        text-decoration: underline;
        padding-right: 10px;
        font-weight: 500;
    }
}

.option-user {
    padding: 0px 30px !important;

    @media screen and (max-width:768px) {
        padding: 30px 20px !important;
    }
}

.plan-number {
    li {
        font-size: 14px;
        margin: 0 10px;

        a {

            color: #B6632F;

            .fa {
                transform: translateX(10px);
                color: var(--blue-new-color);
            }
        }
    }
}

.account-details {
    @media screen and (max-width: 992px) {
        margin: 40px 0 40px;
    }
}

.account_height {
    min-height: calc(100vh - 12px - 70px);
}

.account-login {
    padding: 3.8rem 2.7rem;
    min-height: calc(100vh - 12px - 70px);

    &.pro-file-ad {
        padding: 45px 0;
    }

    &.account-new {
        padding: 3.8rem 0rem 0;
    }

    .card-user-id-number {
        padding: 0rem 2.5rem;

        @media screen and (max-width : 768px) {
            padding: 0rem 0.2rem 0;

        }
    }

    @media screen and (max-width : 768px) {
        padding: 2.8rem 0.5rem 0;
        margin-bottom: 20px;
    }

    .account-log-out {
        margin-bottom: 4rem;
    }

    .link-account {
        color: var(--hover-color);
        font-size: 17px;

        text-decoration: underline;
        font-weight: 500;

        &:hover {
            color: var(--yellow-color);
        }

        .fa {
            padding-right: 6px;
        }
    }

    .link-account-num {
        padding: 8px 10px;
        font-size: 16px;
        border-radius: 5px;
        text-decoration: underline;
        font-weight: 500;
        text-transform: capitalize;

        &:hover {
            color: var(--yellow-color);
        }
    }

    .account-heading {
        font-size: 20px;
        font-weight: 600;
        color: var(--black);
    }

    .account-sub-heading {
        font-size: 18px;
        color: var(--grey);

        @media screen and (max-width : 1199px) {
            text-align: left !important;
        }
    }



    .card-table {
        border-radius: 0;
        width: 100%;
        overflow: auto;


        @media screen and (max-width : 992px) {
            overflow-x: hidden;
        }

        .table-list {

            thead {

                @media screen and (max-width : 768px) {
                    display: none;
                    position: absolute;
                }

                tr {
                    th {
                        padding: 10px 0px 10px 20px;
                        border-bottom: 1px solid var(--light-blue-400);
                        font-size: 14px;
                        font-weight: 600;
                        color: var(--blue-grey);
                        white-space: nowrap;

                        &.total-number {
                            text-align: right;
                            padding-right: 20px;

                        }

                    }

                    &:last-child th {
                        padding-right: 20px;
                    }
                }


            }

            &.product {

                thead {
                    tr {
                        th {
                            text-align: right;
                        }
                    }
                }

                tbody {
                    tr {
                        border-bottom: 1px solid var(--light-blue-400);

                        td {
                            text-align: right;
                            vertical-align: baseline;

                            @media screen and (max-width :768px) {
                                padding-right: 0px !important;
                                transform: translateX(-20px);

                            }

                            &:nth-child(5) {
                                padding-right: 20px;

                            }

                            @media screen and (max-width : 768px) {

                                &:nth-child(1):before {
                                    content: "PRODUCT";
                                    font-weight: 500;
                                    transform: translateX(30px);
                                }

                                // &:nth-child(2):before {
                                //     content: "SKU";
                                //     font-weight: 500;
                                //     transform: translateX(30px);
                                // }

                                &:nth-child(2):before {
                                    content: "PRICE";
                                    font-weight: 500;
                                    transform: translateX(30px);
                                }

                                &:nth-child(3):before {
                                    content: "QUANTITY";
                                    font-weight: 500;
                                    transform: translateX(30px);
                                }

                                &:nth-child(4):before {
                                    content: "TOTAL";
                                    font-weight: 500;
                                    transform: translateX(30px);
                                }
                            }

                            &::before {
                                @media screen and (max-width : 768px) {

                                    float: left;

                                }
                            }

                            .order-id-children {
                                text-align: left;

                                @media screen and (max-width : 768px) {
                                    text-align: right;
                                }
                            }

                        }

                        &:last-child td {
                            padding-right: 20px;
                        }
                    }
                }

                ul {
                    list-style: none;
                    padding: 0;


                    li {
                        color: ar(--light-blue-100);
                        font-size: 16px;

                        @media screen and (max-width: 400px) {
                            font-size: 15px;
                        }

                        &.minor {
                            padding-bottom: 15px;

                            @media screen and (max-width:768px) {
                                padding-bottom: 5px;

                            }

                            a {
                                font-size: 16px;
                                color: var(--blue-dark);
                                margin: 30px 0;

                                @media screen and (max-width : 992px) {
                                    padding-left: 0;
                                    font-size: 15px;
                                }
                            }
                        }

                        &.fulfilled {
                            border: 1px solid var(--light-blue-100);
                            display: inline-block;
                            padding: 7px 10px;
                            margin: 10px 0 0;
                        }

                        &.child-total {
                            font-size: 23px;
                            font-weight: 600;

                            padding-top: 15px;
                        }
                    }

                }

                tfoot {

                    .total-list-price {
                        position: relative;
                        padding: 20px;

                        @media screen and (max-width: 499px) {
                            padding: 20px 10px;
                        }

                        ul {
                            margin-bottom: 0;
                        }

                        .amount-list {
                            text-align: right;
                            position: absolute;
                            top: 20px;
                            right: 20px;
                            width: 100%;

                        }
                    }
                }

            }


            &.table-use-list {
                tbody {

                    tr {
                        td {
                            @media screen and (max-width : 768px) {

                                flex-direction: column !important;
                            }
                        }
                    }

                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid var(--light-blue-400);

                    td {
                        padding: 20px 20px 20px 20px;
                        white-space: nowrap;

                        @media screen and (max-width:768px) {
                            padding: 20px 0px 20px 0px;
                            white-space: wrap;
                        }

                        input {
                            border: 1px solid var(--light-blue-400);
                            cursor: pointer;
                            padding: 8px 6px;
                            color: var(--blue-dark);

                            @media screen and (max-width : 768px) {
                                width: 140px;
                            }
                        }

                        &.table-date-list {
                            text-align: right;
                            padding-right: 20px;

                            @media screen and (max-width : 768px) {
                                padding-right: 20px;
                                padding-bottom: 10px !important;
                            }
                        }

                        @media screen and (max-width : 768px) {
                            display: flex;
                            justify-content: space-between;
                            text-align: right;
                            padding-right: 20px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            font-weight: 600;
                            font-size: 16px;
                        }

                        &::before {
                            @media screen and (max-width : 768px) {
                                float: left;
                            }
                        }

                    }
                }
            }

            .order {
                cursor: pointer !important;
            }

            .id-number-user {
                background-color: var(--blue-dark);
                color: var(--white);
                text-align: center;
                border-radius: 7px;
            }

        }

        // body style

        body {
            background: #9BC86A;
            padding: 20px;
        }

        blockquote {
            color: white;
            text-align: center;
        }
    }

    .card-list {
        height: 100%;

        .list-group {
            list-style: none;
            padding: 0px 10px 10px;

            li {
                padding: 5px 0 0;


                &.link {
                    padding: 13px 0 !important;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    a {
                        color: var(--blue-dark);
                        font-size: 17px;
                        font-weight: 600;
                        width: 100%;

                        &:hover {
                            color: var(--yellow-color);
                        }
                    }

                    .fa {
                        color: var(--blue-dark);
                    }

                }

                &.dollar {
                    font-size: 50px;
                    color: var(--blue-grey);
                    font-family: 'Gotham Condensed';
                    font-weight: 800;
                    margin-bottom: 0;

                }

                &.next {
                    color: #969596;
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 5px
                }

                &.pay {
                    font-size: 19px;
                    color: var(--blue-grey);
                }

                &.dollar-list {
                    display: flex;
                    align-items: center;
                    padding: 25px 10px;

                    .web-image {
                        width: 65px;
                        height: 65px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 100%;
                            margin-right: 20px;

                        }
                    }

                    .web-text {
                        padding-left: 7px;

                        p {
                            font-size: 20px;
                            font-weight: 500 !important;
                            margin-bottom: 0;
                            padding-bottom: 10px;
                        }

                        h5 {
                            font-size: 17px;
                            font-weight: 600;
                            color: var(--blue-grey);
                            letter-spacing: 1px;
                        }
                    }



                }
            }
        }
    }

    .t-shirt {
        margin: 50px 0px 0px 0;
        border: 1px solid var(--light-blue-400);
    }

    .t-shirt-heading {
        margin-top: 120px;
        padding: 0 80px;
        min-height: 81%;

        @media screen and (max-width : 992px) {
            padding: 0 20px;
            margin-top: 30px;
        }
    }


    .user-card {
        max-width: 1050px;
        width: 100%;
        margin: 0 auto;
        border-radius: 0;

        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--blue-dark);
            padding: 20px;
            border-radius: 0;



            @media screen and (max-width : 568px) {
                padding: 20px 5px;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: center;
            }

            h4 {
                margin-bottom: 0;
                font-size: 17px;
                color: var(--white);
                text-transform: uppercase;

                &.date-select {
                    display: flex;
                }
            }
        }

        .coverage-yearly {
            padding: 20px;

            @media screen and (max-width : 992px) {
                padding-bottom: 0;

            }

            .logo-cover {
                display: flex;

                @media screen and (max-width: 768px) {
                    justify-content: center;
                    flex-direction: column;

                    p {
                        text-align: center;
                    }
                }

                img {
                    width: 100px;
                    margin-right: 20px;

                    @media screen and (max-width: 768px) {
                        width: 70px;
                        margin: 0 auto;
                    }
                }

                h5 {
                    font-size: 24px;
                    padding-top: 20px;
                    color: var(--blue-dark);
                    text-align: center;
                    font-family: 'Gotham Condensed';

                    @media screen and (max-width :768px) {
                        padding-top: 0;
                    }
                }

                p {
                    color: var(--grey);
                    font-size: 23px;
                    font-weight: 500;
                    margin-bottom: 0;
                }
            }

            h5 {
                font-size: 25px;
                margin-bottom: 0;
                color: var(--blue-dark);
                font-family: 'Gotham Condensed';
            }

            .user-list-gun {
                list-style: none;
                padding: 0px 0 10px;

                @media screen and (max-width : 992px) {
                    margin-bottom: 0;
                }

                li {
                    padding: 13px 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--light-blue-400);
                    color: var(--grey);

                    span {
                        font-size: 17px;
                        color: var(--grey);
                        padding-right: 10px;
                    }

                    img {
                        width: 25px;
                        height: 25px;
                    }
                }

                .click-button {
                    margin-top: 20px;
                    text-align: right;

                    .view {
                        background-color: transparent;
                        box-shadow: none;
                        color: var(--hover-color);
                        font-size: 15px;
                        font-weight: 500;
                        border: 1px solid transparent;
                        padding: 0;
                        text-decoration: underline;

                        &:hover {
                            color: var(--yellow-color);
                        }
                    }
                }
            }

            .additional-heading {
                font-size: 19px;
                font-weight: 600;
                padding-top: 20px;
                color: var(--blue-dark);
            }

            .additional-menu-list {
                padding: 0;
                list-style: none;

                li {
                    padding: 2px 0;
                    font-size: 16px;
                    color: var(--blue-grey);
                }

                .additional-heading-2 {
                    font-size: 22px;
                    font-weight: 600;
                    padding-top: 20px;
                    color: var(--blue-dark);
                }
            }
        }
    }

    .member-doc {
        max-width: 1050px;
        width: 100%;
        margin: 50px auto 0px;

        .mem-doc {
            list-style: none;
            padding: 0;

            li {
                font-size: 23px;
                color: var(--black);
                font-weight: 600;
                border-bottom: 1px solid var(--light-blue-400);
                padding-bottom: 10px;

                &.font {
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 10px;

                    a {
                        color: var(--blue-dark);
                    }
                }
            }
        }
    }
}

.js-youtube {
    width: 100%;
    height: 600px;

    @media screen and (max-width : 768px) {
        height: 200px;
    }
}

.tabs-bills {
    .nav-tabs {
        border-bottom: 1px solid var(--light-blue-400);

        .nav-link {
            border-bottom: 2px solid transparent;
            color: var(--black);
            letter-spacing: 1px;
            font-weight: 500;
            font-size: 18px;

            @media screen and (max-width : 599px) {
                font-size: 16px;
                padding-left: 7px;
                padding-right: 7px;
            }

            &.active {
                border-color: transparent;
                background-color: transparent;
                border-bottom: 2px solid var(--black);
                color: var(--black);
            }

            &:hover {
                border-color: transparent;
                border-bottom: 2px solid var(--blue-dark);

            }
        }
    }
}

.pane-bill {
    .up-coming-bill {
        padding: 20px 0 0;
        font-size: 17px;
        font-weight: 600;
        color: var(--black);
    }

    .bills-card {
        border-radius: 0px;
        overflow: auto;

        .amount-card {
            white-space: nowrap;

            thead {
                background-color: var(--light-blue-400);

                th {
                    padding: 17px 15px;
                    border-bottom: 1px solid var(--light-blue-100);

                    @media screen and (max-width : 768px) {
                        padding: 17px 45px;
                    }
                }
            }

            tbody {

                tr {
                    border-bottom: 1px solid var(--light-blue-100);

                    &:last-child {
                        border-bottom: 0;
                    }
                }

                td {
                    padding: 17px 15px;
                    font-size: 16px;

                    @media screen and (max-width : 992px) {
                        padding: 13px 15px 5px 45px;
                        font-weight: 600;
                    }

                    &:last-child {
                        @media screen and (max-width : 768px) {
                            padding-bottom: 15px;
                            font-weight: 600;
                        }
                    }
                }
            }

            .details-list2 {
                color: var(--hover-color);
                font-size: 15px;
                font-weight: 500;
                background-color: transparent;
                border: 0px solid;
                box-shadow: none;
                text-decoration: underline;

                &:hover {
                    color: var(--yellow-color);
                }
            }
        }
    }

    .history {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;

        @media screen and (max-width : 568px) {
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 20px;
            margin-top: 0;
        }

        button {
            box-shadow: none;
            background-color: transparent;
            color: var(--hover-color);
            font-size: 15px;
            border: transparent;
            text-decoration: underline;
            font-weight: 500;

            &:hover {
                color: var(--yellow-color);
            }


        }
    }
}

.safety {
    margin: 10px 0 50px;

    .shooting {
        margin-bottom: 60px;

        img {
            height: 450px;
            object-fit: cover;
            width: 100%;

            @media screen and (max-width : 992px) {
                height: 200px;
            }
        }

        @media screen and (max-width : 992px) {
            margin-bottom: 30px;

        }

        &.mt-img {
            @media screen and (max-width : 992px) {
                margin-top: 20px;

            }
        }
    }


}

.safety-text {
    padding: 40px 70px;
    margin-bottom: 0 auto;

    @media screen and (max-width:992px) {
        padding: 0 10px;
    }

    h3 {
        font-family: 'Gotham Condensed';
        font-size: 50px;

        @media screen and (max-width : 499px) {
            font-size: 40px;

        }
    }

    p {
        font-size: 22px;
        color: var(--grey);
        padding: 10px 0;
    }
}

.user-details {
    padding: 1rem 0rem;

    @media screen and (max-width : 768px) {
        padding: 0;
    }

    .link-profile {
        border-bottom: 1px solid #dee2e6;
        margin-top: 30px;

        ul {
            padding: 0;
            list-style: none;
            margin-bottom: 0;
            position: relative;
            padding-left: 35px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 150px;
                height: 4px;
                background-color: var(--blue-dark);
            }

            li {
                padding-bottom: 5px;

                a {
                    font-size: 17px;
                    text-transform: uppercase;
                    color: var(--blue-dark);
                }
            }
        }
    }
}

.drop-m {
    transform: translateX(70px) !important;
}

.dropdown {
    .dropdown-menu {
        margin-top: 50px !important;
        padding: 0;

        .dropdown-item {
            font-size: 15px;
            font-weight: 600;
            color: var(--light-blue-100);
            padding: 13px 45px 13px 10px;
            background-color: var(--blue-dark);
            border: 1px solid #d7d6d680;

            &.border-list {
                border-bottom: 1px solid #d7d6d680;
            }

            &:hover {
                background-color: transparent;
                color: var(--blue-dark);
            }

            &.active-color {
                background-color: var(--white);
                color: var(--blue-dark);
            }

        }
    }
}

.primary-heading {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 20px;
    color: var(--blue-dark);
    font-weight: 600;
}

.size-modal {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.billing-list {
    .billing-heading {
        border-bottom: transparent;
        font-size: 14px;
        font-weight: 600;
        color: var(--black);
    }

    .people {
        font-size: 12px;
        margin-left: 10px;
        letter-spacing: 3px;
        color: rgb(165, 156, 156);
    }

    .billing-body {
        padding-top: 0;
        padding-bottom: 0;
    }

    .billing-footer {
        justify-content: flex-start;
        border-top: transparent;
        padding-top: 0;
        padding-bottom: 10px;
    }
}

.return-details {
    margin: 0 auto;

    .return-account {
        font-size: 16px;
        padding-left: 0;
        color: var(--hover-color);
        text-decoration: underline;
        display: flex;
        max-width: 200px;

        &:hover {
            color: var(--yellow-color);
        }
    }
}

.profile {
    font-size: 30px;
    color: var(--black);
    font-family: 'Gotham Condensed';
    margin: 0;
    padding: 0 0 10px 0;

    &.pro-changes {
        text-align: left;
        padding: 10px 0;

        @media screen and (max-width:992px) {
            padding-left: 0px;
        }
    }
}

.unite {
    padding: 10px;

    &:focus {
        outline: 0;
        box-shadow: none;
        border: 1px solid var(--blue-dark);
        border-radius: 5px;
    }
}

.default-user {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40px;

    @media screen and (max-width : 1199px) {
        padding: 0 0px;
    }

    .card-address {
        max-width: 100%;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }


    .card {
        flex: 1 0 350px;
        max-width: calc(33.33333% - 10px);
        padding: 0px 20px;
        position: relative;
        padding-bottom: 10px;

        @media screen and (max-width : 1292px) {
            max-width: calc(48.3% - 10px);
        }

        @media screen and (max-width : 592px) {
            max-width: calc(100% - 10px);
        }

        .text-profile {
            position: absolute;
            right: 0px;
            top: 0px;
            margin-bottom: 0;
            background-color: var(--blue-dark);
            color: var(--white);
            font-size: 15px;
            font-weight: 500;
            padding: 3px 6px;
        }

        .default-body {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: auto;

            h2 {
                font-size: 18px;
                color: var(--black);
                font-weight: 600;
                padding-top: 20px;
                margin-bottom: 0;
            }

            p {
                line-height: 30px;
                font-size: 18px;
                color: var(--grey);
                margin: 0;
                padding: 5px 0;
            }

            .default-bottom {
                margin-top: auto;
            }
        }
    }
}

.right-video {
    position: relative;

    .video-iframe {
        width: 100%;
        height: 600px;

        @media screen and (max-width: 768px) {
            height: 100%;
        }

    }
}

.check-focus {
    &:focus {
        border-radius: 0;
        box-shadow: 0 0 0 0.1rem #f1c7ac;
    }
}

.last-accordion {
    border-bottom: 1px solid transparent !important;

}

.last-item-accordion {
    margin-bottom: 50px;

    @media screen and (max-width : 768px) {
        margin-bottom: 20px;
    }
}

.owl-nav {
    text-align: center;

    .owl-prev {
        width: 40px;
        height: 40px;

        span {
            font-size: 60px;
        }
    }
}

.owl-next {
    span {
        font-size: 60px;
    }
}

.delete-text {
    h3 {
        font-size: 22px;
        color: var(--black);
        margin: 0;
        letter-spacing: 1px;
    }

    p {
        font-size: 18px;
        margin: 0;
        color: var(--black);
    }
}

.cancel-button {
    border-top: 1px solid transparent;

    .cancel {
        background-color: transparent;
        box-shadow: none;
        padding: 5px 20px;
        border: 1px solid var(--blue-dark);

        font-size: 20px;

        &.ok {
            background-color: var(--blue-dark);
            border: transparent;
            color: var(--white);

        }
    }
}

.pop-list {
    position: relative;

    @media screen and (max-width : 768px) {
        margin-top: 0px;
    }

    .back-bb {
        margin-left: 115px;
        background-color: transparent;
        display: flex;
        border: 0;
        margin-top: 30px;
        font-weight: 500;

        @media screen and (max-width : 1192px) {
            margin-left: 0;
        }

        .fa {
            padding-right: 5px;
            font-size: 20px;
            color: var(--hover-color);
        }

        span {
            text-decoration: underline;
            font-size: 16px;
            color: var(--hover-color);

        }

        @media screen and (max-width : 992px) {
            margin-left: 0;
        }

        &:hover .fa {
            color: var(--yellow-color);
        }

        &:hover span {
            color: var(--yellow-color);
        }

    }
}

.card-payment {
    padding: 20px;
    margin-top: 10px;
    margin-left: 120px;

    @media screen and (max-width : 1192px) {
        margin-left: 0;
    }

    h5 {
        font-size: 18px;
        padding-bottom: 10px;
    }
}

.items-plan {
    background-color: #f5f5f5;
    height: 100%;
    border-left: 1px solid #d6d5d5;
    padding: 50px;

    &.items-border-left-remove {
        border-left: 0;
    }

    &.item-plan-new {
        background-color: transparent;
        padding: 10px 50px;

        @media screen and (max-width : 992px) {
            padding: 10px 0px;

        }
    }

    @media screen and (max-width : 992px) {
        padding: 20px 26px 20px 20px;
        border-left: 0px;
    }

    @media screen and (max-width : 412px) {
        padding: 20px 6px 20px 6px;
    }

    .table {

        tbody {
            tr {
                td {
                    border-bottom: transparent;

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .plan {

        &.plan-2 {
            display: flex;
        }

        .plan-box-camera {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e6e6e6;
            border: 1px solid #d6d5d5;
            margin-right: 10px;
            border-radius: 15px;
            position: relative;

            .fa {
                font-size: 25px;
                color: #b3b0b0;
            }

            &::after {
                content: '1';
                position: absolute;
                right: -6px;
                top: -7px;
                width: 20px;
                height: 20px;
                background-color: var(--grey);
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--white);
                font-size: 12px;
            }
        }
    }
}

.stripe {
    margin-left: 120px;

    @media screen and (max-width : 992px) {
        margin-left: 0;
    }
}

.discount-input {
    padding: 13px 10px;
}

.apply-button {
    background-color: var(--blue-dark);
    border: transparent;
    padding: 13px 12px;
    border-radius: 5px;
    color: var(--white);
    font-weight: 600;
    font-size: 17px;

    &.disabled {
        pointer-events: none !important;
        background-color: #b1b1b1 !important;
    }
}

.remove-coupon {
    font-weight: bold;
    text-decoration: underline;
    color: red;
    margin-left: 1.25rem;
    width: 50%;
    cursor: pointer;
}

.status {
    font-size: 17px;
}

// minor-house-hold-children-yearly css start

.children-yearly {
    text-align: center;

    .children {
        margin-bottom: 80px;

        @media screen and (max-width:768px) {
            padding: 0 40px;

        }
    }

    .plan-quantity {
        font-size: 14px;
        margin: 0;
        color: var(--input-color);
    }

    h1 {
        font-size: 28px;
        font-weight: 600;
        word-spacing: 2px;
        padding-bottom: 10px;

        @media screen and (max-width:768px) {
            font-size: 23px;
        }
    }

    h5 {
        font-size: 20px;
        color: var(--input-color);
        font-weight: 400;
        padding-bottom: 20px;
    }

    .plan-plus-minus {
        border: 1px solid var(--black);
        display: inline-block;
        padding: 10px 0;
        margin-top: 10px;
        align-items: center;
        justify-content: center;

        button {
            background-color: transparent;
            border: 1px solid transparent;
            padding: 0 15px;
            font-size: 15px;


            .fa {
                color: var(--grey);
            }
        }
    }

    .add-to-cart {
        margin: 20px 0;
    }

    .accordion-materials {
        max-width: 650px;
        width: 100%;
        margin: 0 auto;

        .accordion {
            .accordion-item {
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;

                .accordion-header {
                    .accordion-button {
                        padding: 13px 20px;
                    }

                    .accordion-button:not(.collapsed) {
                        box-shadow: none !important;
                        background-color: transparent !important;
                        color: var(--black);
                    }

                    .accordion-button:focus {
                        background-color: transparent;
                        box-shadow: none;
                    }

                    .accordion-button::after {
                        background-size: 15px;
                    }

                    .accordion-button:not(.collapsed)::after {
                        background-image: url(../../public/images/down.png);
                    }

                }

                .accordion-body {
                    text-align: left;
                    padding: 0px 56px;
                }

            }

        }
    }

    .share-button {
        margin-top: 30px;
        cursor: pointer;


        img {
            width: 20px;
            margin-right: 10px;
        }

        span {
            &:hover {
                border-bottom: 1px solid var(--black);
            }
        }
    }
}

.collection {
    background-color: var(--light-blue-400);
    margin-top: 40px;

    .image-text {
        border-right: 1px solid var(--light-blue-100);
        padding: 60px 65px;

        @media screen and (max-width : 992px) {
            padding: 60px 20px;
            border-right: 0px solid;
        }

        p {
            font-size: 20px;
            color: var(--grey);
            padding-top: 20px;
            margin-bottom: 0;
        }
    }

    .black-image {
        padding: 60px 65px;
        width: 300px;
        height: 250px;
        margin: 0 auto;

        @media screen and (max-width :992px) {
            border-bottom: 1px solid var(--light-blue-100);
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;

            @media screen and (max-width :992px) {
                width: 100px;
                height: 100px;
                margin: 0 auto;
                display: block;
            }
        }
    }
}

.free-shipping {
    padding: 60px;

    @media screen and (max-width:"992px") {
        padding: 20px 0;
    }

    .free-text {
        h4 {
            font-size: 17px;
            color: var(--black);
            font-weight: 600;
        }

        p {
            font-size: 17px;
            color: var(--grey);
        }
    }
}

.also {
    padding: 0 60px;

    @media screen and (max-width : 992px) {
        padding: 0;
    }

    .card_content {
        .card_information {
            background-color: var(--light-blue-400);
            max-width: 600px;
            width: 100%;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 10px;

            .card_heading {
                a {
                    color: var(--black);
                    font-size: 15px;
                    font-weight: 600;
                }
            }

            &:hover a {
                border-bottom: 1px solid var(--black);
            }
        }

        .card_footer {
            padding-top: 20px;

            h4 {
                margin: 0;
                font-size: 20px;
                color: var(--input-color);
            }
        }
    }
}

// minor-house-hold-children-yearly css send

.card-header {
    background-color: transparent;
}

.video-iframe {
    width: 100%;
    height: 600px;

    @media screen and (max-width : 768px) {
        width: 100%;
        height: 200px;
    }
}

.thank-you {
    padding: 20px;
    margin: 0px auto;

    @media screen and (max-width:992px) {
        text-align: left;
        max-width: 100%;
        padding: 20px 0;
    }

}

.details-order {
    margin: 10px 0 10px;
    font-weight: 600;
    font-size: 20px;
}

.information {
    margin-bottom: 0px;
    font-weight: 600;
}

.add-bill {
    font-weight: 600;
}

.summary-order {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 414px;
    width: 100%;
    margin-bottom: 30px;

    @media screen and (max-width : 1199px) {
        max-width: 100%;
    }

    @media screen and (max-width : 992px) {
        margin-bottom: 0px;
    }

    h4 {
        font-size: 20px;
        color: var(--black);
        margin-bottom: 0;

        font-weight: 600;
    }

    p {
        margin-bottom: 0;
        color: #3498DB;
        cursor: pointer;
    }
}

.login-alert {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @media screen and (max-width :768px) {
        align-items: flex-start;
    }
}

.reset-alert {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @media screen and (max-width :768px) {
        align-items: flex-start;
    }
}


.remove-coupon {
    transform: translateY(13px);
    padding-right: 10px;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    text-decoration: none;
}

.close-input {
    display: block;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    color: red;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.referral-code-css {
    font-size: 16px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: var(--yellow-color);
    }

}

.discount {
    position: relative;

    .discount-remove {
        position: absolute;
        top: 21px;
        right: 20px;
        color: red;
        font-weight: 800;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &.disable {
            pointer-events: none;
            opacity: 0.6;
        }
    }
}

.error-mag {
    @media screen and (min-width : 990px) {
        margin-left: 120px;
    }
}

.billing-list-add {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        margin-bottom: 0;
        font-size: 18px;
        cursor: pointer;
        font-size: 15px;
        color: var(--hover-color);

        &:hover {
            color: var(--yellow-color);
        }
    }
}

.right-to {
    padding: 50px 40px 30px;

    @media screen and (max-width : 992px) {
        padding: 50px 0px 30px;
    }

    .ranked {
        font-size: 40px;
        font-weight: 600;
        color: var(--black);
        margin-bottom: 0;

        @media screen and (max-width : 992px) {
            font-size: 20px;
        }
    }

    img {
        width: 300px;
        display: block;
        margin: 0 auto 30px;
    }

    .review {
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 400;

        a {
            text-decoration: underline;

        }
    }

    .find-heading {
        font-size: 43px;
        font-weight: 600;
        color: var(--black);
        margin-top: 90px;

        @media screen and (max-width : 992px) {
            font-size: 33px;
        }
    }
}

.event-dote {
    position: relative;

    &.padding-add {
        padding-bottom: 120px;
        margin-bottom: 100px;
    }

    .loading-dote {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -70px;
    }
}

.Insurance {
    padding: 30px 35px;

    .container {
        @media screen and (min-width : 120px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    h4 {
        font-size: 38px;
        font-weight: 600;
        color: var(--black);
        margin-bottom: 0;
        padding-bottom: 40px;
    }

    h5 {
        @extend h4;
        font-size: 20px;
        padding-bottom: 16px;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            font-size: 17px;
            padding-bottom: 25px;
            color: var(--grey);

        }
    }
}

.width-cuman {
    width: 100%;

    @media screen and (min-width : 992px) {
        max-width: 500px;
    }
}

.payment-check-now {
    width: 100%;

    @media screen and (min-width : 992px) {
        max-width: 440px;
    }
}

.table-text-right {
    @media screen and (max-width : 1199px) {
        text-align: right;

    }

    @media screen and (max-width : 568px) {
        white-space: nowrap;
    }
}

.link-text {
    color: var(--hover-color);
    font-size: 17px;
    text-decoration: underline;
    font-weight: 500;
    padding-left: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &:hover {
        color: var(--yellow-color);
    }

    &:active {
        color: var(--yellow-color);
    }
}


.react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 10px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    @media screen and (max-width : 442px) {
        width: 100%;
    }

    input {
        @media screen and (max-width : 442px) {
            width: 100%;
            margin-bottom: 0 !important;
        }
    }
}

.select-dialog {
    height: 36px !important;
    padding: 6px 10px 5px 10px !important;
}

.user-tab {
    display: flex;
    gap: 30px;
    align-items: flex-start;

    @media screen and (max-width : 868px) {
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    .m-d {
        background-color: var(--light-blue-400);
        padding: 20px;
        flex: 1 0 430px;

        @media screen and (max-width : 450px) {
            flex: 1;
        }

        .user-name-b {
            font-weight: 600;
            color: var(--black);
            font-size: 16px;
        }

        h3 {
            border-bottom: 1px solid var(--light-blue-100);
            font-size: 17px;
            padding-bottom: 15px;
            font-weight: 600;
            color: var(--blue-grey);
        }

        p {
            font-size: 17px;
            font-weight: 500;
            line-height: 25px;
            color: var(--blue-new-color);
            line-height: 30px;
            margin-bottom: 30px
        }
    }
}

.card-user-id-number {
    @media screen and (max-width : 768px) {
        margin-bottom: 20px;
    }
}

.gd-carousel {
    position: unset;

    .react-multiple-carousel__arrow {
        position: absolute;
        z-index: 1 !important;
        background-color: var(--blue-dark);

        &:hover,
        &:focus,
        &:valid {
            background-color: var(--yellow-color);

        }
    }

    .react-multiple-carousel__arrow--left {
        left: calc(-3% + 1px) !important;

        @media screen and (max-width : 1199px) {
            left: calc(-2% + 1px) !important;
        }

        @media screen and (max-width : 412px) {
            left: calc(0% + 1px) !important;
        }
    }

    .react-multiple-carousel__arrow--right {
        right: calc(-3% + 1px) !important;

        @media screen and (max-width : 1199px) {
            right: calc(-2% + 1px) !important;
        }

        @media screen and (max-width : 442px) {
            right: calc(0% + 1px) !important;
        }
    }
}

.react-multi-carousel-dot-list {
    position: absolute;
    bottom: -40px;
}

.react-multi-carousel-dot button {
    border-color: var(--blue-dark) !important;
    width: 22px;
    height: 22px;
}

.react-multi-carousel-dot--active button {
    background-color: var(--blue-dark) !important;
}

.applied {
    white-space: nowrap;

    @media screen and (max-width : 992px) {
        white-space: wrap;
    }
}

.account-payment-status {
    width: 120px;
    text-align: left;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.overview-card {
    height: 100% !important;
    justify-content: space-between !important;
}



// Wavier ---Start

.h-d {
    font-size: 18px;
    font-weight: 600;
    color: #2c333c;
}

.l-b {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #2c333c !important;
    display: block;
}

.i-t {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #2c333c !important;
    margin-bottom: 10px;
}

input:focus {
    box-shadow: none !important;
}

.count-parent {
    font-size: 14px;
    font-weight: 500;
    color: #2c333c;
    background-color: rgb(228, 228, 228);
    padding: 10px;
    border-radius: 5px;
}

.theme-btn {
    background-color: #142D52;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    display: inline-flex;
    border: 0;
    box-shadow: none;
    padding: 6px 12px;
    border-radius: 5px;
    margin-top: 10px;
    border: 1px solid transparent;
}

.theme-btn:hover {
    background-color: #fff;
    color: #142D52;
    border: 1px solid #142D52;
}

.marketing {
    padding: 10px;
}

.m-h {
    font-size: 20px;
    font-weight: 600;
    color: #2c333c;
    padding-bottom: 20px;
}

.page-h {
    font-size: 20px;
    font-weight: 500;
    color: #2c333c;
    margin-bottom: 0;
    padding-bottom: 20px;
}

.page-s {
    font-size: 17px;
    font-weight: 700;
    color: #2c333c;
    margin-bottom: 0;
    text-decoration: underline;
}

.waiver-text {
    font-size: 16px;
    font-weight: 500;
    color: #2c333c;
    letter-spacing: 1px;
    line-height: 130%;
}

.listing-name ul li {
    font-size: 14px;
    font-weight: 500;
    color: #2c333c;
}

.page-p {
    font-size: 15px;
    font-weight: 500;
    color: #2c333c;
    margin-bottom: 0;
}

.today-l {
    font-size: 15px;
    font-weight: 400;
    color: #2c333c;
}

.today-l span {
    font-weight: 600;
}

/* edit css */

.card {
    // box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
    box-shadow: none;
    margin-bottom: 1rem;
}

// Signature
.container-signature {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
}

.signature-pad {
    flex: 1;
    width: 100%;
    position: relative;
}

.signature-pad canvas {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: crosshair;
    background: white;
    height: 100%;
    width: 100%;
}

.button-container {
    margin-top: 20px;
    text-align: center;
}

.button-container button {
    padding: 10px 12px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin: 0 5px;
}

.clear-btn {
    background-color: #ff4444;
    color: white;
}

.save-btn {
    background-color: #142D52;
    color: white;
}

.button-container button:hover {
    opacity: 0.9;
}

.message {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    border-radius: 4px;
    animation: fadeIn 0.3s ease-in-out;
}

.success {
    background: #dff0d8;
    color: #3c763d;
    border: 1px solid #d6e9c6;
}

.error {
    background: #f2dede;
    color: #a94442;
    border: 1px solid #ebccd1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

// Wavier ---End

.grid-list-new-ui {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    gap: 10px;

    @media screen and (max-width: 992px) {
        grid-template-columns: 1fr;
    }
}

.grid-list-new-ui .card {
    padding: 30px 10px 10px;
    display: grid;
    gap: 20px;
    // grid-template-columns: 1fr 1fr;
    position: relative;

    @media screen and (max-width: 992px) {
        grid-template-columns: 1fr;
    }
}

.grid-list-new-ui .card .grid-list-u {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 992px) {
        grid-template-columns: 1fr;
    }
}

.grid-list-new-ui .card .only-btn {
    // grid-row: 3 / 3;
    grid-column: 1 / -1;
    // padding-left: 18px;

    @media screen and (max-width: 992px) {
        padding-left: 0px;
    }
}

.grid-list-new-ui .card .card-top-list {
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: 0;
    background-color: var(--blue-dark);
    color: var(--white);
    font-size: 15px;
    font-weight: 500;
    padding: 3px 6px;
    border: 0;
}

.grid-list-new-ui .card .floating-group.mb-f {
    margin-bottom: 0;
}

.firing-pin {
    border-bottom: 0;
}

.firing-pin .firing-heading {
    font-size: 2.5rem;
    line-height: 100%;
    font-weight: 400;

}

.grid-lay-out {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    align-items: flex-start;

    @media screen and (max-width : 1092px) {
        grid-template-columns: 1fr;
    }
}



.grid-tabs-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    column-gap: 20px;

    @media screen and (max-width : 492px) {
        grid-template-columns: 1fr;
    }
}

.grid-tabs-list .w-full-row {
    grid-column: 1/-1;
}

.list-user-create ul {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0;
}

.list-user-create ul li {
    display: flex;
    padding-bottom: 10px;
    font-size: 16px;
    color: var(--grey);
    gap: 20px;
}

.list-user-create ul li span {
    margin-left: auto;
    font-size: 17px;
    font-weight: 500;
    color: var(--blue);
    word-break: break-all;
}

.get-text:hover {
    color: var(--yellow-color);
}