.choose-plans-description {

    font-family: 'dashiellbright-medium';

    @media screen and (max-width : 992px) {
        padding: 0 10px;
    }

    .plans-price {
        max-width: 950px;
        width: 100%;
        margin: 0px auto 50px;
        border: 3px solid var(--light-blue-100);
        padding: 30px;

        @media screen and (max-width : 992px) {
            text-align: center;
            padding: 30px 0 !important;
        }
    }

    .logo-form {
        img {
            max-width: 300px;
            width: 100%;
            margin-bottom: 10px;
        }

        .plan-features {
            font-size: 12px;
            color: var(--grey);
            font-weight: 600;
            letter-spacing: 3px;
            padding-left: 10px;

            @media screen and (max-width : 992px) {
                display: none;
            }
        }
    }

    .associate-price {
        text-align: center;

        .coverage {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
        }

        .monthly-price {
            font-size: 40px;
            font-weight: 600;
            font-family: 'dashiellbright-medium';
            margin-bottom: 0;

            .currency-symbol {
                font-size: 19px;
                top: -1em;
            }

            .month {
                font-size: 20px;
                color: var(--grey);
            }
        }

        .divider {
            display: flex;
            align-items: center;
            justify-content: center;

            .hr-line {
                width: 70px;
            }

            .associate-price-divider {
                text-transform: uppercase;
                padding: 0 15px;
                font-size: 12px;
                color: var(--grey);
                font-weight: 600;
            }
        }

        .yearly-price {
            font-size: 30px;
            font-weight: 500;
            font-family: 'dashiellbright-medium';

            .currency-symbol {
                font-size: 15px;
                top: -1em;
            }

            .yearly-text {
                font-size: 20px;
                color: var(--grey);
            }
        }
    }

    // .Plan-detail {

    //     @media screen and (max-width : 992px) {
    //         padding: 0;
    //     }

    //     ul {
    //         list-style: none;
    //         padding: 0;
    //         margin-bottom: 0;

    //         li {
    //             display: flex;
    //             align-items: center;
    //             justify-content: space-between;
    //             border-top: 1px solid var(--light-blue-100);

    //             @media screen and (max-width : 992px) {
    //                 display: flex;
    //                 flex-direction: column;
    //                 border-top: 1px solid #e2e2e2;
    //             }

    //             h4 {
    //                 flex: 50%;
    //                 display: flex;
    //                 margin: 0;
    //                 padding: 15px 0;
    //                 border-right: 1px solid #e2e2e2;
    //                 // border-top: 1px solid #e2e2e2;
    //                 font-size: 13px;
    //                 font-family: 'Corbert';
    //                 font-weight: 600;

    //                 @media screen and (max-width : 992px) {
    //                     border-right: 0px solid;
    //                     padding: 6px 0;
    //                 }

    //             }

    //             .correct-image {
    //                 flex: 50%;
    //                 text-align: center;
    //                 line-height: normal;
    //                 padding: 12px 0;

    //                 // border-top: 1px solid #e2e2e2;
    //                 @media screen and (max-width : 992px) {
    //                     padding: 6px 0;
    //                 }

    //                 img {
    //                     width: 17px;
    //                 }
    //             }

    //         }
    //     }
    // }

    .options {
        background-color: var(--blue);
        margin: 0;
        padding: 8px 10px;
        color: #fff;
        font-weight: 700;
        font-size: 13px;
        font-family: 'Corbert';
        letter-spacing: 1px;
    }

    // .plan-list {
    //     margin-bottom: 0;

    //     li {

    //         h4 {
    //             padding: 24px 0 !important;

    //             @media screen and (max-width :992px) {
    //                 padding: 6px 0 !important;
    //             }
    //         }

    //         .correct-image {
    //             flex: 50%;
    //             text-align: center;
    //             line-height: normal;

    //             .monthly-price {
    //                 font-size: 14px;
    //                 font-weight: 500;
    //                 margin-bottom: 0;
    //                 font-family: 'Corbert';
    //             }

    //             .yearly-price {
    //                 margin-bottom: 0;
    //                 font-size: 12px;
    //                 font-weight: 500;
    //                 font-family: 'Corbert';
    //                 color: var(--grey);
    //             }
    //         }

    //         .name {
    //             padding: 48px 0 !important;

    //             @media screen and (max-width : 992px) {
    //                 padding: 0 !important;
    //             }
    //         }

    //         .correct-image-1 {
    //             flex: 50%;
    //             text-align: center;
    //             line-height: normal;
    //             padding-top: 20px;

    //             .fee {
    //                 margin-bottom: 0;
    //                 font-size: 14px;
    //                 font-family: 'Corbert';
    //                 font-weight: 600;
    //                 color: var(--grey);
    //             }


    //             .button-golden {
    //                 text-transform: uppercase;
    //                 font-size: 13px;
    //                 font-weight: 600;
    //                 letter-spacing: 1px;
    //                 font-family: 'Assistant';
    //             }
    //         }
    //     }
    // }
}

.table-list-user-plan {
    .table {
        width: 100%;
        font-family: 'dashiellbright-medium';
        margin-bottom: 0;

        .table-list {
            border-top: 1px solid var(--light-blue-100);


            @media screen and (max-width : 992px) {
                display: flex;
                flex-direction: column;
                border-bottom: none !important;
            }

            .table-title {
                width: 50%;
                border-right: 1px solid var(--light-blue-100);
                padding: 13px 0;

                @media screen and (max-width : 992px) {
                    border-right: 0px solid transparent;
                    width: 100%;
                    border-bottom-width: 0px !important;
                    padding: 2px 0 0;
                }

                .table-heading {
                    margin-bottom: 0px;
                    font-size: 15px;
                    font-family: "Corbert";
                    font-weight: 600;

                    @media screen and (max-width:992px) {
                        color: var(--blue);
                    }

                }
            }

            .table-image {
                text-align: center;

                @media screen and (max-width : 992px) {
                    border-bottom: 1px solid var(--light-blue-100);
                }

                img {
                    width: 15px;
                }
            }
        }

        tr {
            @extend .table-list;



            .table-plan {
                @extend .table-title;
                padding: 22px 0;

                @media screen and (max-width : 992px) {
                    padding: 5px 0;
                }


                h4 {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-family: "Corbert";
                    font-weight: 600;

                    // padding-top: 10px
                    @media screen and (max-width:992px) {
                        color: var(--blue);
                    }
                }
            }

            .table-monthly {
                @extend .table-image;

                .monthly-price {
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 0;
                    font-family: "Corbert";
                    color: var(--blue-grey);
                }

                .yearly-price {
                    margin-bottom: 0;
                    font-size: 15px;
                    font-weight: 500;
                    font-family: "Corbert";
                    color: var(--grey);
                }

                p {
                    margin-bottom: 0;
                }
            }

            .correct-image-1 {
                text-align: center;
                padding-top: 30px;

                @media screen and (max-width : 992px) {
                    border-bottom-width: 0px
                }

                .fee {
                    margin-bottom: 0;
                    font-size: 17px;
                    font-family: 'Corbert';
                    font-weight: 600;
                    color: #323e48;
                }


                .button-golden {
                    font-family: 'Assistant';
                }
            }


        }

        .plan-button {
            border: 1px solid transparent;
        }
    }
}