:root {
    --white: #fff;
    --black: #000;
    --blue: #002060;
    --grey: #121212BF;
    --light-blue-100: #d8dade;
    --light-blue-400: #ececef;
    --blue-grey: #323e48;
    --input-color: #969393;
    --text-color: #121212;
    --hover-color: #0d6efd;
    --blue-dark: #1A2A3F;
    --yellow-color: #BA952E;
    --link-color: #E7E5D8;
    --grey-light: #1A2A3F90;
    --blue-new-color: #7b858c;

}

$blue-new-color: var(--blue-new-color);
$grey-light: var(--grey-light);
$link-color: var(--link-color);
$white: var(--white);
$black: var(--black);
$primary: var(--blue);
$grey : var(--grey);
$light-grey: var(--light-blue-100);
$light-blue-grey: var(--light-blue-400);
$secondary: var(--blue-grey);
$input-color: var(--input-color);
$color-text: var(--text-color);
$hover-color: var(--hover-color);
$blue-dar: var(--blue-dark);
$yellow-color: var(--yellow-color);