.headroom.headroom--pinned.headroom--scrolled {
    z-index: 2 !important;
}

.headroom-wrapper {
    height: 100px !important;
}

.navbar {

    border-bottom: 5px solid var(--yellow-color);
    width: 100%;
    background-color: var(--blue-dark);
    position: relative;

    .container {
        @media screen and (min-width: 1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.4rem 1.4rem;

        .logo {
            img {
                width: 13rem;

                @media screen and (max-width : 1199px) {
                    width: 13rem;
                }

            }
        }

        nav {
            margin-left: auto;
            display: flex;

            .menu-bar {
                display: flex;
                align-items: center;
                padding: 0;
                padding-right: 10px;
                margin-bottom: 0;

                @media screen and (max-width : 1268px) {
                    padding-right: 10px;
                    padding-left: 35px;
                }

                li {
                    list-style: none;
                    display: block;
                    padding: 0 17px;

                    &.link-join {
                        background-color: var(--yellow-color);
                        padding: 2px;
                        clip-path: polygon(10% -10%, 93% 1%, 100% 22%, 100% 80%, 92% 100%, 7% 100%, 0% 80%, 0% 20%);
                        margin-right: 10px;

                        a {
                            color: var(--blue-dark);
                            font-weight: 600;
                            text-transform: uppercase;
                            font-size: 12px;
                        }
                    }

                    a {
                        text-decoration: none;
                        font-size: 15px;
                        letter-spacing: 1.25px;
                        text-decoration: unset;
                        color: var(--link-color);
                        font-weight: 300;
                        text-align: right;
                        display: flex;
                        align-items: center;
                        column-gap: 1px;

                        &:hover {
                            color: var(--white);
                        }

                        img {
                            width: 14px;
                            transform: translateY(-2px);
                        }

                        &.btn-join {
                            background-color: var(--blue-dark);
                            color: var(--white);
                            padding: 10px 30px;
                            clip-path: polygon(10% -10%, 93% 1%, 100% 22%, 100% 80%, 92% 100%, 7% 100%, 0% 80%, 0% 20%);

                            &:hover {
                                background-color: #E7E5D8 !important;
                                color: var(--blue-dark);
                            }

                            &.active-btn {
                                background-color: #E7E5D8;

                                color: var(--blue-dark);
                            }
                        }

                        &.pd {
                            padding: 10px 20px;
                            white-space: nowrap;
                        }
                    }

                    &.active {
                        position: relative;

                        a {
                            color: var(--yellow-color);
                        }

                        @media screen and (min-width : 992px) {
                            &::before {
                                content: '';
                                position: absolute;
                                height: 20px;
                                background-color: var(--yellow-color);
                                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                                width: 40px;
                                top: 42px;
                                left: 49%;
                                transform: translate(-50%, -0%);
                            }
                        }
                    }
                }
            }
        }

        .log-in {

            a {
                text-decoration: none;
                font-size: 16px;
                border-left: 1px solid gainsboro;
                color: var(--input-color);
                padding: 15px 0 10px 80px;
                display: block;
                font-weight: 700;
                font-family: 'Corbert';

                @media screen and (max-width : 1200px) {
                    padding: 15px 0 10px 15px;
                }

                &:hover {
                    color: var(--blue);

                }

                &.active {
                    color: var(--blue);
                }


            }


        }
    }

}

.account_log_in {
    width: 40px;
    aspect-ratio: 1/1;
    background-color: var(--yellow-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;

    .fa {
        color: var(--white);
        font-size: 20px;
    }

    &:hover .fa {
        transform: scaleX(1.05) scaleY(1.05);
    }

    a {
        border-left: 1px solid transparent;
    }
}

// mobile menu css

.navbar-mobile-menu {
    header {
        padding: 15px 30px;

        @media screen and (max-width : 768px) {
            padding: 10px 5px;
        }

        .logo {
            img {
                width: 205px;

                @media screen and (max-width:768px) {
                    width: 205px;

                }
            }
        }

        #click {
            display: none;
        }

        #click:checked~.menu-btn i::before {
            content: "\f00d";
            color: var(--white);
        }

        .menu-btn {
            position: absolute;
            right: 40px;
            top: 32px;
            font-size: 30px;

            .fa {
                color: var(--white);
            }

            @media screen and (max-width:768px) {
                right: 20px;

            }
        }

        nav {

            .menu-bar {
                padding: 0;
                display: block !important;

                li {
                    border-top: 1px solid gainsboro;
                    padding: 7px 0;
                    display: block;


                    &:hover {
                        background-color: #1212120A;
                        color: #000;
                    }

                    a {
                        padding: 5px 20px;
                        display: block;
                        text-align: left;

                        &:hover {
                            color: var(--white);

                        }
                    }

                    &.active {
                        color: var(--yellow-color);
                    }
                }

                @media screen and (max-width : 992px) {
                    position: fixed;
                    top: 105px;
                    left: 0;
                    width: 100%;
                    height: 100vh;
                    background-color: var(--blue-dark);
                    transform: translateX(-100%);
                    transition: all 0.5s ease-in-out;
                    overflow-y: auto;
                    z-index: 999 !important;
                    padding-bottom: 170px;

                }
            }
        }

        #click:checked~.menu-bar {
            transform: translateX(0);
        }

    }

}