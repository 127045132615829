.owl-nav {
    position: absolute;
    top: 100%;
    transform: translate(-50%);
    left: 50%;
    display: none;
    align-items: center;

}

.owl-dots {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%);
    margin-top: 20px;
    margin-left: 13px;
    display: flex;
}

.owl-dot {
    display: inline-block;
    margin-right: 5px;
    background-color: var(--white) !important;
    width: 13px;
    height: 13px;
    border-radius: 50px;


    &.active {
        background-color: var(--blue) !important;
    }
}