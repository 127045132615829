:root {
    --font-heading-scale: 0.75;
}


.banner-heading {
    color: var(--white);
    font-family: 'Corbert';
    font-weight: 800;
    font-size: 78px;
    letter-spacing: 0;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1rem;
    display: block;
    margin-bottom: 1rem;

    @media screen and (max-width: 992px) {
        font-size: 33px;
        line-height: 40px;
    }
}

.sub-title-2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    word-spacing: 2px;
    color: var(--blue);
}

.bear-text {
    font-size: 19px;
    color: var(--grey);
    line-height: 30px;

    @media screen and (max-width : 568px) {
        text-align: left;
    }
}


.sub-title-heading {
    font-size: 50px;
    font-family: 'Gotham Condensed';

    @media screen and (max-width : 399px) {
        font-size: 40px;
    }
}